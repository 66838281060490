import { Fab } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Pagination = styled('div')`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Image = styled('img')`
  margin: auto;
  display: flex;
  max-width: 100%;
  max-height: 100%;
`;

export const AddButton = styled(Fab)`
  right: 15px;
  bottom: 15px;
  position: fixed;
`;
