import { v4 } from 'uuid';

export const getTokenAccess = () => localStorage.getItem('user_token');

export const onlyNumbers = (string) => string.replace(/\D+/g, '');

export const orderByName = (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

export const orderByGroupName = (a, b) =>
  a.order_group < b.order_group ? -1 : a.order_group > b.order_group ? 1 : 0;

export const notNull = (value) => value !== null && value !== undefined;

export const not = (value) => !value;

export const isNotEmpty = (value) =>
  notNull(value) ? value.split('').length > 0 : false;

export const first = (list) => list[0];

export const moneyFormat = (value = 0) =>
  Number(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

export const replaceSourcePath = (path) =>
  path.replace('source-path', process.env.REACT_APP_HOST);

export const generateKey = () => v4();

export const deepClone = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  let cloned;
  let i;

  if (obj instanceof Date) {
    cloned = new Date(obj.getTime());
    return cloned;
  }

  if (obj instanceof Array) {
    let l;
    cloned = [];
    for (i = 0, l = obj.length; i < l; i++) {
      cloned[i] = deepClone(obj[i]);
    }

    return cloned;
  }

  cloned = {};
  for (i in obj) {
    if (obj.hasOwnProperty(i)) {
      cloned[i] = deepClone(obj[i]);
    }
  }

  return cloned;
};

export const calculateTotal = (items) => {
  if (!items) return 0;

  const result =
    items.reduce((total, { totalChange }) => total + totalChange, 0).toFixed(2) || 0;

  return Number(result).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};
