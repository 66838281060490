import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import MaterialIcon from '../../../../../components/MaterialIcon';
import { moneyFormat } from '../../../../../functions';
import { Divider } from './Styles';

const stageMapper = {
  creating: 'Criação da comanda',
  updating: 'Atualização na comanda',
};

export function HistoryDetails({ open, historyDetails, closeModal }) {
  const getChangeType = (quantity) => (quantity > 0 ? 'Adicionado' : 'Removido');

  return (
    <Dialog open={open} onClose={closeModal} fullWidth>
      <DialogTitle style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
        {stageMapper[historyDetails.stage]} {historyDetails.table}
        <span>
          <Button color="primary" size="small" onClick={closeModal}>
            <MaterialIcon name="close" size={24} style={{ width: '24px' }} />
          </Button>
        </span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontSize: 12, fontWeight: 'bold' }}>
          {historyDetails.createdAt}
        </DialogContentText>
        <Divider />

        <h3 style={{ marginBottom: 10, fontWeight: 'normal' }}>Itens de modificados:</h3>

        <div
          style={{
            height: 250,
            overflowY: 'auto',
          }}
        >
          {historyDetails.changedItems.map((item) => (
            <div key={item.id}>
              {Number(item.unitPrice) > 0 && (
                <DialogContentText
                  key={item.id}
                >{`${getChangeType(item.quantity)} ${item.quantity}x ${moneyFormat(item.unitPrice)} ${item.name}`}</DialogContentText>
              )}
            </div>
          ))}

          <Divider />

          <h3 style={{ marginBottom: 10, fontWeight: 'normal' }}>Itens de combos:</h3>

          {historyDetails.changedItems.map((item) => (
            <div key={item.id}>
              {Number(item.unitPrice) === 0 && (
                <DialogContentText
                  key={item.id}
                  style={{ fontWeight: 'bold' }}
                >{`${item.quantity}x de ${item.name}`}</DialogContentText>
              )}
            </div>
          ))}

          <Divider />
        </div>
      </DialogContent>
    </Dialog>
  );
}
