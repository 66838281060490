import React, { useEffect } from 'react';

import * as S from './Styles';

function NotFound({ history }) {
  useEffect(() => {
    setTimeout(() => history.push('/login'), 2000);
  }, [history]);

  return (
    <S.Wrapper>
      <h1>404</h1>
      <h2>Not found</h2>
      <p>Parece que você se perdeu, iremos te levar a tela de login em 3s</p>
    </S.Wrapper>
  );
}

export default NotFound;
