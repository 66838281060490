import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { generateKey } from '../../../../../functions';

const Message = withReactContent(Swal);

const messageSettings = {
  title: `Finalizar esta comanda?`,
  text: 'Lembre-se, esta ação não poderá ser desfeita.',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText: 'Voltar',
  confirmButtonText: 'Sim, quero finalizar!',
  reverseButtons: true,
};

const messageSettingsPending = {
  title: `Realize a impressão do comprovante`,
  text: 'É necessário imprimir o comprovante pelo responsável que a finalizou.',
  icon: 'error',
  showConfirmButton: false,
  showCancelButton: true,
  cancelButtonColor: '#d33',
  cancelButtonText: 'Retornar a tela anterior',
  reverseButtons: true,
};

const orderIsClosed = (order) => 'closed_by' in order && order.closed_by !== null;

export default function Payment({
  open,
  data,
  showError,
  handleClosed,
  successCreateItem,
}) {
  const [loading, setLoading] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const finishPayment = async () => {
    setHidden(true);

    const orderFounded = await Api.get(`/order/find?id=${data._id}`);

    const message = orderIsClosed(orderFounded)
      ? messageSettings
      : messageSettingsPending;

    return Message.fire(message).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setHidden(true);
        try {
          const { message } = await Api.post(`/order/edit?id=${data._id}`, {
            status: 1,
          });
          successCreateItem(message);

          setTimeout(() => window.location.reload(), 1000);
        } catch ({ message }) {
          setLoading(false);
          showError(message);
        }
      } else {
        handleClosed();
        setHidden(false);
      }
    });
  };

  const sumItens = (ordersList, clients_data) => {
    const result = ordersList
      .map((item) => (!clients_data.length ? item.amount : item.to.length))
      .reduce((total, value) => total + value, 0);

    return result;
  };

  const sumValueItens = (sumList, clients_data) =>
    sumList
      .map((item) =>
        !clients_data.length ? item.amount * item.price : item.to.length * item.price,
      )
      .reduce((total, value) => total + value, 0);

  const printerItem = async () => {
    try {
      const { message } = await Api.get(`/order/generate-receipt?id=${data._id}`);
      successCreateItem(message);
    } catch ({ message }) {
      showError(message);
    }
  };

  const applyDiscount = (price) => {
    if (data.internal_request) {
      return price - price * 0.4;
    }

    return price;
  };

  return (
    <Dialog open={open} onClose={handleClosed} hidden={hidden} fullScreen>
      <DialogTitle>FINALIZAR COMANDA</DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={3}>
          Detalhes de consumo da mesa {data?.table}.
        </DialogContentText>

        {data?.orders?.map((item) => (
          <Grid container spacing={2} key={generateKey()}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="text.secondary">
                {item.name}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {item.description}
              </Typography>
              <Grid container justifyContent="space-between">
                <Typography variant="subtitle1" color="text.secondary">
                  {applyDiscount(item.price).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Quantidade: {!data?.clients_data.length ? item.amount : item.to.length}
                </Typography>
              </Grid>
              <Divider />
            </Grid>
          </Grid>
        ))}

        <Typography
          marginTop={3}
          style={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
          gutterBottom
          variant="subtitle1"
          component="div"
        >
          Total:
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {sumItens(data?.orders || [], data?.clients_data || []) > 1
            ? `${sumItens(data?.orders || [], data?.clients_data || [])}x itens`
            : `${sumItens(data?.orders || [], data?.clients_data || [])}x item`}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {applyDiscount(
            sumValueItens(data?.orders || [], data?.clients_data || []),
          ).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Typography>

        {data.internal_request && (
          <>
            <Divider style={{ marginTop: '10px' }} />

            <div
              style={{
                width: '100%',
                marginTop: '10px',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              Um desconto de 40% foi aplicado aos valores.
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClosed}>
          Voltar
        </Button>
        <Button color="success" variant="outlined" onClick={() => printerItem()}>
          Imprimir
        </Button>
        <LoadingButton
          disabled={localStorage.getItem('user_level_name') === 'Funcionário'}
          onClick={() => finishPayment()}
          endIcon={loading ? <MaterialIcon name="check" /> : null}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {loading ? 'Finalizando...' : 'Finalizar'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
