import * as S from './Styles';

import AddIcon from '@mui/icons-material/Add';
import DeliveryIcon from '@mui/icons-material/DeliveryDining';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableIcon from '@mui/icons-material/TableRestaurant';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alerts from '../../../components/Alert';
import Loading from '../../../components/Loading';
import Api from '../../../connections/Api';
import DataEmpty from './Components/DataEmpty';
import Create from './Components/Modals/create';
import Edit from './Components/Modals/edit';
import Payment from './Components/Modals/payment';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { deepClone, generateKey } from '../../../functions';

const Message = withReactContent(Swal);

const messageSettings = {
  title: `Cancelar esta comanda?`,
  text: 'Esta ação só poderá ser desfeita por um administrador.',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText: 'Voltar',
  confirmButtonText: 'Sim, quero cancelar!',
  reverseButtons: true,
};

const messageRestoreSettings = {
  title: `Reabrir comanda?`,
  icon: 'alert',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText: 'Cancelar',
  confirmButtonText: 'Reabrir',
  reverseButtons: true,
};

const messageSettingsItem = {
  title: `Aviso!`,
  text: 'Deseja marcar todos os itens como entregue?',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText: 'Cancelar',
  confirmButtonText: 'Confirmar',
  reverseButtons: true,
};

const defaultMessageError =
  'Erro ao realizar esta ação, verifique os dados e tente novamente!';

export default function Dashboard() {
  const [isUser, setIsUser] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [showCreateProduct, setShowCreateProduct] = React.useState(false);
  const [showEditProduct, setShowEditProduct] = React.useState({
    status: false,
    orderId: null,
  });
  const [showPayment, setShowPayment] = React.useState({
    status: false,
    data: {},
  });
  const [totalItems, setTotalItems] = React.useState(0);
  const [alert, setAlert] = React.useState({ open: false, message: '' });

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  const confirmCancelItem = async (id, orderStatus) =>
    Message.fire(orderStatus === 1 ? messageRestoreSettings : messageSettings).then(
      async ({ isConfirmed }) => {
        if (isConfirmed) {
          try {
            const status = orderStatus === 1 ? 0 : 2;

            await Api.post(`/order/edit?id=${id}`, { status });

            window.location.reload();
          } catch {
            setAlert({
              ...alert,
              open: true,
              status: 'error',
              message: 'Não foi possível executar essa ação',
            });
          }
        }
      },
    );

  const hasUnprintedOrders = (orders) => orders.some((order) => !order.ready);

  const printerItem = async (id, orders) => {
    if (hasUnprintedOrders(orders)) {
      try {
        const { message } = await Api.get(`/order/print?id=${id}`);
        setAlert({ ...alert, open: true, status: 'success', message });
      } catch ({ message }) {
        setAlert({ ...alert, open: true, status: 'error', message });
      }
    }
  };

  const showMessageError = (message = defaultMessageError) => {
    setAlert({
      ...alert,
      open: true,
      status: 'error',
      message,
    });
  };

  const showPaymentConfirm = async (id) => {
    const response = await Api.get(`/order/find?id=${id}`);

    if (response.orders.map((item) => item.ready).includes(false)) {
      await Message.fire(messageSettingsItem).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          response.orders.forEach((item, key) => {
            response.orders[key] = { ...item, ready: true };
          });

          try {
            const { _id: id, orders, table } = response;
            const { message } = await Api.post(`/order/edit?id=${id}`, {
              table,
              orders,
            });

            setAlert({ ...alert, open: true, status: 'success', message });
          } catch ({ message }) {
            setAlert({ ...alert, open: true, status: 'error', message });
          }
        }
      });
    } else {
      setShowPayment({ status: true, data: response });
    }
  };

  const editOrderById = async (orderId) => {
    setShowEditProduct((current) => ({ ...current, orderId, status: true }));
  };

  const renderItemCard = (data) =>
    data.map(
      ({
        _id,
        note,
        table,
        orders,
        delivery: isDelivery,
        status: orderStatus,
        closed_by,
        created_at,
        created_by,
        order_group,
        clients_data,
      }) => (
        <Grid item xs={12} sm={12} md={3} key={generateKey()}>
          <Paper
            sx={{
              p: 2,
              my: 1,
              mx: 'auto',
              flexGrow: 1,
              maxWidth: 500,
              margin: 'auto',
            }}
            xs
            align="center"
          >
            <Grid item container>
              <Grid xs item container spacing={2} wrap="nowrap" direction="column">
                <Grid item>
                  <Typography gutterBottom variant="title1" component="h1" align="left">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: 'column',
                      }}
                    >
                      {isDelivery ? (
                        <DeliveryIcon
                          style={{ fontSize: 40, color: 'GrayText', width: '100%' }}
                        />
                      ) : (
                        <TableIcon
                          style={{ fontSize: 40, color: 'GrayText', width: '100%' }}
                        />
                      )}
                      <p style={{ fontSize: '1.8rem' }}>{table}</p>
                    </div>
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="title2"
                    fontSize={16}
                    component="h4"
                    align="left"
                    fontWeight="normal"
                  >
                    {`${
                      String(order_group).replace('null', '').length > 0
                        ? `Comanda Compartilhada: ${order_group}`
                        : 'Comanda individual'
                    }`}
                  </Typography>
                  <Typography
                    variant="title2"
                    component="h4"
                    color="text.secondary"
                    align="left"
                    fontWeight="normal"
                  >
                    {DateTime.fromMillis(created_at).toFormat('dd MMM yyyy, HH:mm a', {
                      locale: 'pt-br',
                    })}
                  </Typography>
                  <Typography
                    align="left"
                    variant="title2"
                    fontSize={16}
                    component="h4"
                    fontWeight="normal"
                    gutterBottom
                  >
                    Criada por: {created_by?.name || 'Não encontrado'}
                  </Typography>
                  {closed_by !== null && (
                    <Typography
                      align="left"
                      variant="title2"
                      fontSize={16}
                      component="h4"
                      fontWeight="normal"
                      gutterBottom
                    >
                      Fechado por: {closed_by?.name || 'Não encontrado'}
                    </Typography>
                  )}
                </Grid>

                <Grid item style={{ width: '100%', margin: '0px' }}>
                  <Accordion onClick={() => null}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 10,
                          justifyContent: 'center',
                        }}
                        align="left"
                      >
                        Lista de itens solicitados
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {orders.map((item) => (
                        <Grid
                          container
                          key={generateKey()}
                          style={{
                            backgroundColor: '#FFFFFF',
                            padding: 5,
                            borderRadius: '3px',
                            border: '2px solid #D3D3D3',
                            margin: 0,
                            marginBottom: 10,
                          }}
                        >
                          <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                              <Typography variant="subtitle1" color="text.secondary">
                                {item.name}
                              </Typography>
                              <Typography variant="subtitle1" color="text.secondary">
                                Quantidade:{' '}
                                {!clients_data.length ? item.amount : item.to.length}
                              </Typography>
                            </Grid>
                            <Grid container>
                              {'selecteds' in item ? (
                                item.selecteds.map((items) => (
                                  <Grid container>
                                    <Typography
                                      variant="subtitle1"
                                      color="text.secondary"
                                      component="span"
                                    >
                                      {`${items.amount}x ${items.name}`}
                                    </Typography>
                                  </Grid>
                                ))
                              ) : 'combos_released' in item &&
                                item.combos_released > 0 ? (
                                <Typography variant="subtitle1" color="text.secondary">
                                  Sem itens adicionais
                                </Typography>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </AccordionDetails>
                  </Accordion>

                  <Grid container spacing={1} direction="row">
                    <Grid item>
                      <Typography
                        marginTop={3}
                        variant="subtitle1"
                        color="text.secondary"
                      >
                        {clients_data.length && note
                          ? 'Anotações do Cliente'
                          : 'Anotações do Garçom'}
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary" align="left">
                        {note}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        disabled={isUser}
                        size="small"
                        color="error"
                        variant="outlined"
                        onClick={() => confirmCancelItem(_id, orderStatus)}
                      >
                        {orderStatus === 1 ? 'Reabrir Comanda' : 'Cancelar'}
                      </Button>
                    </Grid>
                    {orderStatus === 0 ? (
                      <>
                        <Grid item>
                          <Button
                            size="small"
                            color="info"
                            variant="outlined"
                            onClick={() => editOrderById(_id)}
                          >
                            Editar
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            color="success"
                            variant="outlined"
                            disabled={
                              !hasUnprintedOrders(orders) ||
                              orderStatus === 1 ||
                              localStorage.getItem('user_level_name') !== 'Administrador'
                            }
                            onClick={() => printerItem(_id, orders)}
                          >
                            Imprimir
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            color="inherit"
                            variant="outlined"
                            disabled={orderStatus === 1}
                            onClick={() => showPaymentConfirm(_id)}
                          >
                            Fechar a conta
                          </Button>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ),
    );

  const successCreateItem = async (message) => {
    setAlert({
      ...alert,
      status: 'success',
      open: true,
      message,
    });

    return true;
  };

  const goToPage = async (currentPage) => {
    const search_dashboard = localStorage.getItem('search_dashboard') ?? '';
    const filter =
      localStorage.getItem('filter') === 'null'
        ? ''
        : (JSON.parse(localStorage.getItem('filter'))?.label ?? '');

    try {
      const result = await Api.get(
        `/order/list?page=${currentPage}&query=${filter}&search=${search_dashboard}`,
      );
      setData(result.data);
      setTotalItems(result.total);
      setPage(Number(result.page));
    } catch ({ message }) {
      setData([]);
      setAlert({ ...alert, open: true, status: 'error', message });
    }
  };

  const updateItem = (item) => {
    const cloneData = deepClone(data);

    cloneData.forEach(({ _id }, key) => {
      if (_id === item._id) {
        Object.getOwnPropertyNames(item).forEach((element) => {
          cloneData[key][element] = item[element];

          setData(cloneData);
        });

        setAlert({
          ...alert,
          open: true,
          status: 'success',
          message: `A comanda da mesa ${item.table} foi atualizada com sucesso!`,
        });
      }
    });
  };

  const getFilter = () => {
    if (localStorage.getItem('filter') === 'null') {
      return '';
    }

    return JSON.parse(localStorage.getItem('filter'))?.label ?? '';
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const accessLevel = localStorage.getItem('user_level_name');
      const search_dashboard = localStorage.getItem('search_dashboard') ?? '';

      setIsUser(accessLevel === 'Funcionário');

      try {
        const { data, total, page } = await Api.get(
          `/order/list?query=${getFilter()}&search=${search_dashboard}`,
        );

        setData(data);
        setTotalItems(total);
        setPage(Number(page));
      } catch ({ message }) {
        setData([]);
      }
    };

    fetchData();
  }, []);

  if (data === null) return <Loading />;

  if (data.length === 0)
    return (
      <>
        <DataEmpty showCreateProduct={() => setShowCreateProduct(true)} />
        <Create
          open={showCreateProduct}
          showError={showMessageError}
          reloadScreen={() => window.location.reload()}
          handleClosed={() => setShowCreateProduct(false)}
          handleClickOpen={() => setShowCreateProduct(true)}
        />
      </>
    );

  const closeEditModal = () => {
    setShowEditProduct((current) => ({ ...current, status: false }));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Alerts
        open={alert.open}
        status={alert.status}
        message={alert.message}
        handleClose={handleClose}
      />

      {showCreateProduct && (
        <Create
          open={showCreateProduct}
          showError={showMessageError}
          reloadScreen={() => window.location.reload()}
          handleClosed={() => setShowCreateProduct(false)}
          handleClickOpen={() => setShowCreateProduct(true)}
          successCreateItem={(message) => successCreateItem(message)}
        />
      )}

      {showEditProduct.status && (
        <Edit
          open={showEditProduct.status}
          orderId={showEditProduct.orderId}
          showError={showMessageError}
          updateItem={(item) => updateItem(item)}
          handleClosed={closeEditModal}
          reloadScreen={() => window.location.reload()}
        />
      )}

      {showPayment.data && (
        <Payment
          data={showPayment.data}
          open={showPayment.status}
          showError={showMessageError}
          updateItem={(item) => updateItem(item)}
          reloadScreen={() => window.location.reload()}
          handleClickOpen={() => setShowPayment(true)}
          successCreateItem={(message) => successCreateItem(message)}
          handleClosed={() => setShowPayment({ ...showPayment, status: false })}
        />
      )}

      <Grid container spacing={{ xs: 2, md: 2 }}>
        {renderItemCard(data)}
      </Grid>
      <S.AddButton color="primary" onClick={() => setShowCreateProduct(true)}>
        <AddIcon />
      </S.AddButton>
      <S.Pagination>
        <Pagination
          color="primary"
          page={page + 1}
          count={Math.ceil(totalItems / 12)}
          onChange={(_event, currentPage) => goToPage(currentPage - 1)}
        />
      </S.Pagination>
    </Box>
  );
}
