import TextField from '@mui/material/TextField';
import React from 'react';

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Alerts from '../../../components/Alert';
import Api from '../../../connections/Api';
import { moneyFormat, orderByName } from '../../../functions';
import DataEmpty from './DataEmpty';

import * as S from './Styles';

function InternalOrders() {
  const theme = useTheme();

  const [data, setData] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [finalDate, setFinalDate] = React.useState(new Date());
  const [initialDate, setInitialDate] = React.useState(new Date());
  const [alert, setAlert] = React.useState({ open: false, message: '' });

  const [employeeSelected, selectEmployee] = React.useState('');
  const [employeeOptions, setEmployeeOptions] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const employeesList = await Api.get('/challenge/able/employees');

      const employeesListResult = employeesList.map(({ name }) => name).sort(orderByName);

      setEmployeeOptions(employeesListResult);
    };

    fetchData();
  }, []);

  const handleClose = () => setAlert({ ...alert, open: false });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      initialDate.setHours(0, 0, 0, 0);
      finalDate.setHours(23, 59, 59, 0);

      const initial = initialDate.getTime();
      const final = finalDate.getTime();

      if (final < initial) {
        setAlert({
          ...alert,
          open: true,
          status: 'error',
          message: 'A data final não pode ser menor que a data inicial',
        });
      } else {
        const bodyData = {
          initialDate: initial,
          finalDate: final,
        };

        const { orders, total } = await Api.post('/internal-orders/find', {
          ...bodyData,
          employeeName: employeeSelected,
        });

        setData(orders);
        setTotal(total);
      }
    } catch ({ message }) {
      setAlert({
        ...alert,
        open: true,
        status: 'error',
        message,
      });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleChangeSelect = ({ target: { value } }) => selectEmployee(value);

  function renderTable() {
    return (
      <S.Container style={{ height: '100%' }}>
        <DataGrid
          columns={[
            {
              field: 'id',
              headerName: 'Nº da comanda',
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'table',
              headerName: 'Mesa',
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
            {
              flex: 1,
              field: 'consumption',
              align: 'center',
              minWidth: 150,
              headerName: 'Total de itens consumidos',
              headerAlign: 'center',
            },
            {
              field: 'created_at',
              headerName: 'Data da ocorrência',
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
            {
              flex: 1,
              field: 'ordersTotal',
              align: 'center',
              minWidth: 150,
              headerName: 'Total',
              headerAlign: 'center',
              renderCell: ({ value }) => moneyFormat(value),
            },
          ]}
          rows={data}
          autoHeight
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
        />
      </S.Container>
    );
  }

  return (
    <>
      <S.Wrapper onSubmit={handleSubmit} component="form" xs={12} sm={3} md={2}>
        <Alerts
          open={alert.open}
          status={alert.status}
          message={alert.message}
          handleClose={handleClose}
        />
        <h2>Comandas Internas</h2>
        <S.Container container>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={initialDate}
                inputFormat="dd/MM/yyyy"
                label="Período inicial"
                onChange={(currentDate) => setInitialDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={finalDate}
                inputFormat="dd/MM/yyyy"
                label="Período final"
                onChange={(currentDate) => setFinalDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <FormControl margin="dense" fullWidth style={{ minWidth: 300 }}>
              <InputLabel id="group-label">Funcionários</InputLabel>
              <Select
                labelId="group-label"
                id="group"
                name="group"
                required
                value={employeeSelected}
                onChange={handleChangeSelect}
                input={<OutlinedInput label="Funcionários" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 150,
                    },
                  },
                }}
              >
                {employeeOptions.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{
                      fontWeight:
                        [employeeSelected].indexOf(name) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.Row>
          <S.Row item>
            <Button
              type="submit"
              variant="contained"
              style={{ height: 55, marginTop: 8 }}
            >
              Consultar
            </Button>
          </S.Row>
          {data === null ? null : (
            <S.Row style={{ height: 55, marginTop: 8 }}>
              <h3 style={{ fontWeight: 'normal' }}>Soma total das comandas:</h3>{' '}
              <h3>{moneyFormat(total)}</h3>
            </S.Row>
          )}
        </S.Container>
      </S.Wrapper>
      <S.Divider />
      {data === null ? <DataEmpty /> : renderTable()}
    </>
  );
}

export default InternalOrders;
