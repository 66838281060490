import { Fab, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Paper)`
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`;

export const TextLoading = styled('h4')`
  font-weight: normal;

  overflow: hidden;
`;

export const AddButton = styled(Fab)`
  right: 15px;
  bottom: 15px;
  position: fixed;
  z-index: 10000;
`;
