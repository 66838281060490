import React from 'react';

import * as S from './Styles';

export default function MaterialIcon({ name, color, size, ...props }) {
  return (
    <S.Icons className="material-icons-outlined" {...props} color={color} size={size}>
      {name}
    </S.Icons>
  );
}
