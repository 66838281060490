import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    margin-top: 80px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
