import * as React from 'react';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import Alerts from '../../../components/Alert';
import MaterialIcon from '../../../components/MaterialIcon';
import MyApi from '../../../connections/Api';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.codemos.com.br/">
        Codemos
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const theme = createTheme();

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      overwrite
      mask="000-000"
      inputRef={ref}
      definitions={{ '#': /[1-9]/ }}
      style={{ textAlign: 'center' }}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Forgot({ setPage, data }) {
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');
  const [alert, setAlert] = React.useState({ open: false, message: '' });
  const [values, setValues] = React.useState({ textmask: '000-0000' });

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = String(data.get('email')).toLowerCase();
    const code = String(data.get('textmask')).toLowerCase();

    if (email) {
      setError(false);
      setLoading(true);

      if (step === 0) {
        try {
          const { message } = await MyApi.get(`/user/forgot?email=${email}`);

          setLoading(false);
          setAlert({
            ...alert,
            open: true,
            status: 'success',
            message,
          });
          setStep(1);
        } catch ({ message }) {
          setLoading(false);
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message,
          });
        }
      } else if (step === 1) {
        try {
          const { message } = await MyApi.get(
            `/user/send-code?code=${code}&email=${userEmail}`,
          );

          setLoading(false);
          setAlert({
            ...alert,
            open: true,
            status: 'success',
            message,
          });
        } catch ({ message }) {
          setLoading(false);
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message,
          });
        }
      }
    } else {
      setError(true);
      setAlert({
        ...alert,
        open: true,
        status: 'error',
        message: `Insira um email`,
      });
    }
  };

  const changeError = ({ target: { value } }) => {
    if (value.length) {
      setError(false);
      setUserEmail(value);
    } else {
      setError(true);
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    if (data) setUserEmail(data);
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <Alerts
        open={alert.open}
        handleClose={handleClose}
        message={alert.message}
        status={alert.status}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            textAlign="center"
            marginTop="40px"
            fontFamily="sans-serif"
          >
            Recuperação de senha
          </Typography>

          <Typography
            component="p"
            variant="p"
            textAlign="center"
            marginTop="20px"
            color="red"
            fontFamily="sans-serif"
          >
            {step === 0
              ? 'Será enviado um código de recuperação para o endereço de e-mail que você informar abaixo.'
              : `Por favor, insira o código que foi enviado para o seu endereço de e-mail.`}
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {step === 0 ? (
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  error={error}
                  required
                  label="Email"
                  type="email"
                  onChange={changeError}
                  defaultValue={data}
                  autoComplete="email"
                  autoFocus
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  endIcon={<MaterialIcon name="send" />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                >
                  {step === 0 ? (loading ? 'Enviando...' : 'Enviar código') : 'Confirmar'}
                </LoadingButton>
              </>
            ) : (
              <>
                <Input
                  name="textmask"
                  fullWidth
                  value={values.textmask}
                  onChange={handleChange}
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom}
                  style={{ marginBottom: '30px', marginTop: '20px' }}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  endIcon={<MaterialIcon name="send" />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                >
                  {step === 0 ? (loading ? 'Verificando...' : 'Verificar') : 'Confirmar'}
                </LoadingButton>
              </>
            )}
            <Grid container textAlign="center">
              <Grid item xs>
                <Link onClick={() => setPage('Login')}>
                  {step === 0 ? 'Voltar' : 'Cancelar'}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
