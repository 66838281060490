import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alerts from '../../../components/Alert';
import Loading from '../../../components/Loading';
import MaterialIcon from '../../../components/MaterialIcon';
import Api from '../../../connections/Api';
import { deepClone, generateKey } from '../../../functions';
import DataEmpty from './Components/DataEmpty';
import Create from './Components/ModalProduct/create';
import Edit from './Components/ModalProduct/edit';

import * as S from './Styles';

const Message = withReactContent(Swal);

export default function Employee() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState({
    status: false,
    data: {},
  });
  const [totalItens, setTotalItens] = React.useState(0);
  const [alert, setAlert] = React.useState({ open: false, message: '' });

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  const messageSettings = (status) => ({
    title: !status ? `Desativar este funcionário?` : `Reativar este funcionário?`,
    text: 'Lembre-se, esta ação poderá ser desfeita futuramente.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancelar',
    confirmButtonText: `Sim, quero ${!status ? 'desabitá-lo' : 'reativá-lo'}!`,
    reverseButtons: true,
  });

  const isActiveOrNot = (name, status) =>
    status ? `Reativado por: ${name}` : `Desativado por: ${name}`;

  const changeStatus = async (id, status) =>
    Message.fire(messageSettings(status)).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const bodyData = {
          status: {
            suspended: !status,
            suspended_by: isActiveOrNot(localStorage.getItem('user_name'), status),
            suspended_at: new Date().getTime(),
          },
        };

        try {
          const { message } = await Api.post(`/employee/edit?id=${id}`, bodyData);
          setAlert({ ...alert, open: true, status: 'success', message });

          setTimeout(() => window.location.reload(), 1000);
        } catch {
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message: 'Não foi possível executar essa ação',
          });
        }
      }
    });

  const deleteEmployee = async (id) =>
    Message.fire({
      title: 'Deletar funcionário?',
      text: 'Lembre-se, esta ação não poderá ser desfeita.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Deletar funcionário',
      reverseButtons: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const { message } = await Api.post(`/employee/edit?id=${id}`, {
            deleted_by: localStorage.getItem('user_id'),
            deleted_at: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'),
          });
          setAlert({ ...alert, open: true, status: 'success', message });

          setTimeout(() => window.location.reload(), 1000);
        } catch {
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message: 'Não foi possível completar a sua solicitação',
          });
        }
      }
    });

  const generateMask = (value) => {
    let i = 0;
    const v = value.toString();

    if (v.length === 11) {
      return '###.###.###-##'.replace(/#/g, () => v[i++] || '');
    }
    if (v.length === 14) {
      return '##.###.###.####/##'.replace(/#/g, () => v[i++] || '');
    }

    return v;
  };

  const convertDate = (created_at) =>
    DateTime.fromMillis(created_at).toFormat('dd/MM/yyyy');

  const renderItemCard = (data) =>
    data.map(
      ({
        _id,
        companie,
        fullname,
        document,
        office,
        status,
        birth_date,
        comments,
        created_at,
        email,
        level_access,
        ...rest
      }) => (
        <Grid item xs={12} sm={12} md={3} key={generateKey()}>
          <Paper
            sx={{
              p: 2,
              my: 1,
              mx: 'auto',
              flexGrow: 1,
              maxWidth: 500,
              margin: 'auto',
            }}
          >
            <Grid item container>
              <Grid xs item container spacing={2} wrap="nowrap" direction="column">
                <Grid item>
                  <Grid container spacing={1} direction="row">
                    <Grid item>
                      <MaterialIcon name="assignment_ind" />
                    </Grid>
                    <Grid item>
                      {' '}
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          textDecoration:
                            'deleted_at' in rest && rest.deleted_at
                              ? 'line-through'
                              : 'none',
                        }}
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        {fullname}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="body2" color="#964b00">
                    {companie.companie_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {generateMask(document)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {birth_date}
                  </Typography>
                </Grid>

                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1" component="div">
                        Função: {office}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {status.suspended
                          ? `Suspendido por ${status.suspended_by} `
                          : `Registrado em ${convertDate(created_at)}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '5px' }}>
                    <Grid item xs>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Observações</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body2">
                            {comments || 'Sem observações'}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        size="small"
                        color={!status.suspended ? 'error' : 'info'}
                        variant="outlined"
                        onClick={() => changeStatus(_id, status.suspended)}
                      >
                        {status.suspended ? 'Reativar' : 'Suspender'}
                      </Button>
                    </Grid>
                    <Grid item>
                      {status.suspended ? (
                        !('deleted_at' in rest && rest.deleted_at) && (
                          <Button
                            size="small"
                            color="error"
                            variant="outlined"
                            onClick={() => deleteEmployee(_id)}
                          >
                            Excluir funcionário
                          </Button>
                        )
                      ) : (
                        <Button
                          size="small"
                          color="success"
                          variant="outlined"
                          onClick={() =>
                            setShowEdit({
                              ...showEdit,
                              status: true,
                              data: {
                                _id,
                                companie: companie.document,
                                fullname,
                                document,
                                office,
                                comments,
                                birth_date,
                                email,
                                level_access,
                              },
                            })
                          }
                        >
                          Editar
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ),
    );

  const showMessageError = (message) => {
    setAlert({
      ...alert,
      open: true,
      status: 'error',
      message:
        message || 'Erro ao realizar esta ação, verifique os dados e tente novamente!',
    });
  };

  const successCreateItem = (message) => {
    setAlert({
      ...alert,
      status: 'success',
      open: true,
      message,
    });
  };

  const goToPage = async (currentPage) => {
    try {
      const result = await Api.get(
        `/employee/list?page=${currentPage}&query=${localStorage.getItem('search_employee') ?? ''}`,
      );

      setData(result.data);
      setTotalItens(result.total);
      setPage(Number(result.page));
    } catch ({ message }) {
      setData([]);
      setAlert({ ...alert, open: true, status: 'error', message });
    }
  };

  const updateItem = (item) => {
    const cloneData = deepClone(data);

    cloneData.forEach(({ _id }, key) => {
      if (_id === item._id) {
        const [name, surname] = item.fullname.split(' ');

        Object.getOwnPropertyNames(item).forEach((element) => {
          cloneData[key][element] = item[element];

          setData(cloneData);
        });

        setAlert({
          ...alert,
          open: true,
          status: 'success',
          message: `O registro de ${name} ${surname} foi atualizado com sucesso!`,
        });
      }
    });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, total, page } = await Api.get(
          `/employee/list?query=${localStorage.getItem('search_employee') ?? ''}`,
        );
        setData(data);
        setTotalItens(total);
        setPage(Number(page));
      } catch ({ message }) {
        setData([]);
        setAlert({ ...alert, open: true, status: 'error', message });
      }
    };

    fetchData();
  }, []);

  if (data === null) return <Loading />;

  if (data.length === 0)
    return (
      <>
        <DataEmpty showCreate={() => setShowCreate(true)} />
        <Create
          open={showCreate}
          handleClickOpen={() => setShowCreate(true)}
          handleClosed={() => setShowCreate(false)}
          successCreateItem={(message) => successCreateItem(message)}
          showError={showMessageError}
          reload={() => window.location.reload()}
        />
      </>
    );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Alerts
        open={alert.open}
        handleClose={handleClose}
        message={alert.message}
        status={alert.status}
      />

      {showCreate && (
        <Create
          open={showCreate}
          handleClickOpen={() => setShowCreate(true)}
          handleClosed={() => setShowCreate(false)}
          successCreateItem={(message) => successCreateItem(message)}
          showError={showMessageError}
          reload={() => window.location.reload()}
        />
      )}

      {showEdit.status && (
        <Edit
          open={showEdit.status}
          handleClickOpen={() => setShowEdit({ ...showEdit, status: true })}
          handleClosed={() => setShowEdit({ ...showEdit, status: false })}
          data={showEdit.data}
          updateItem={(item) => updateItem(item)}
          showError={showMessageError}
        />
      )}

      <Grid container spacing={{ xs: 2, md: 2 }}>
        {renderItemCard(data)}
      </Grid>
      <S.AddButton color="primary" onClick={() => setShowCreate(true)}>
        <AddIcon />
      </S.AddButton>
      <S.Pagination>
        <Pagination
          color="primary"
          page={page + 1}
          count={Math.ceil(totalItens / 12)}
          onChange={(event, currentPage) => goToPage(currentPage - 1)}
        />
      </S.Pagination>
    </Box>
  );
}
