import React, { useEffect, useState } from 'react';
import MaterialIcon from '../../../components/MaterialIcon';
import Api from '../../../connections/Api';
import { generateKey } from '../../../functions';

import * as S from './Styles';

const formatVersion = (version) => `v${String(version).split('').join('.')}`;

export default function ReleaseNotes() {
  const [releaseNotes, setReleaseNotes] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { release_notes } = await Api.get('/release-notes');

        console.log('data:', release_notes);

        setReleaseNotes(release_notes);
      } catch (error) {
        console.error('Erro ao buscar notas de lançamento:', error);
      }
    };

    fetch();
  }, []);

  return (
    <S.ReleaseNotesContainer>
      {releaseNotes.map((note) => (
        <S.ReleaseNote key={note.software_version}>
          <S.Title
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            Versão {formatVersion(note.software_version)}{' '}
            <span style={{ fontSize: 12, fontWeight: 'normal' }}>
              {note.release_date}
            </span>
          </S.Title>

          <S.Releases className="releases">
            <S.ReleasesGroup>
              <MaterialIcon name="speaker_notes" color="green" />
              <S.ReleaseTitle>Atualizações & Melhorias</S.ReleaseTitle>
            </S.ReleasesGroup>
            <ul>
              {note.releases.map((release) => (
                <S.ListItem key={generateKey()}>{release}</S.ListItem>
              ))}
            </ul>
          </S.Releases>
          <S.Fixes className="fixes">
            <S.FixesGroup>
              <MaterialIcon name="bug_report" color="red" />
              <S.FixeTitle>Correções</S.FixeTitle>
            </S.FixesGroup>
            <ul>
              {note.fixes.map((fix) => (
                <S.FixesListItem key={generateKey()}>{fix}</S.FixesListItem>
              ))}
            </ul>
          </S.Fixes>
        </S.ReleaseNote>
      ))}
    </S.ReleaseNotesContainer>
  );
}
