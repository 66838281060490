import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Logo from '../../../assets/Images/Logo.png';
import Alerts from '../../../components/Alert';
import MyApi from '../../../connections/Api';

import * as S from './Styles';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.codemos.com.br/">
        Codemos
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const theme = createTheme();

export default function Login({ history, setPage }) {
  const [alert, setAlert] = useState({ open: false, message: '' });
  const [userEmail, setUserEmail] = useState('');

  React.useEffect(() => {
    const hasToken = localStorage.getItem('user_token');

    if (hasToken) {
      history.push('/sistema/dashboard');
    }
  }, [history]);

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const userEmail = data.get('email');
    const userPassword = data.get('password');

    if (userEmail && userPassword) {
      try {
        setUserEmail(userEmail);

        const { name, token, _id, level_access } = await MyApi.post('/user/login', {
          email: userEmail,
          password: userPassword,
        });

        localStorage.setItem('user_id', _id);
        localStorage.setItem('user_name', name);
        localStorage.setItem('user_token', token);
        localStorage.setItem('user_level_id', level_access._id);
        localStorage.setItem('user_level_name', level_access.access_name);

        history.push('/sistema/dashboard');
      } catch (error) {
        setAlert({
          ...alert,
          open: true,
          status: 'error',
          message: error.message,
        });
      }
    } else {
      setAlert({
        ...alert,
        open: true,
        status: 'error',
        message: 'Email e senha obrigatórios!',
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Alerts
        open={alert.open}
        handleClose={handleClose}
        message={alert.message}
        status={alert.status}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <S.Wrapper>
            <img src={Logo} height={100} alt="Logo da empresa espeto do bagre" />
          </S.Wrapper>

          <Typography component="h1" variant="h5" fontFamily="sans-serif">
            Sistema Espeto do Bagre
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
            />
            <FormControl sx={{ mt: 3, mb: 2 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                value={values.password}
                onChange={handleChange('password')}
                type={values.showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="password"
              />
            </FormControl>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Acessar
            </Button>
            <Grid container textAlign="center">
              <Grid item xs>
                <Link onClick={() => setPage('Forgot', userEmail)}>
                  Esqueceu a senha? Clique aqui
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
