import { Button, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

export const Divider = styled('div')`
  width: 20px;
`;

export const Input = styled('input')`
  display: none;
`;

export const Upload = styled(Button)`
  width: 100%;
  color: black;
  height: 125px;
  border: 1px dashed;
`;

export const Uploader = styled(InputLabel)`
  width: 100%;
  border-radius: 3px;
`;
