import { LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
  TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import React from 'react';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';

import { deepClone } from '../../../../../functions';
import * as S from './Styles';

const options = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

const initialData = {
  day_week: 0,
  discount: null,
  starts_in: null,
  ends_in: null,
  comments: null,
  promotional_menu: [],
};

export default function Create({
  open,
  successCreateItem,
  handleClosed,
  showError,
  reload,
}) {
  const theme = useTheme();

  const [data, setData] = React.useState(initialData);

  const [loading, setLoading] = React.useState(false);

  const [categoryList, setCategoryList] = React.useState([]);

  const [initialDate, setInitialDate] = React.useState(new Date());
  const [finalDate, setFinalDate] = React.useState(new Date());

  const [menu, setMenu] = React.useState([]);
  const [menuList, setMenuList] = React.useState([]);
  const [menuListRef, setMenuListRef] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await Api.get('/menu/full');

      setMenuList(result.map((e) => e.name));
      setMenuListRef(result);
    };

    fetchData();
  }, []);

  const create = async (event, data) => {
    event.preventDefault();

    if (initialDate > finalDate) {
      showError('O período inicial não pode ser maior que o período final');
    } else {
      const stdt = DateTime.fromJSDate(initialDate).toFormat('HH:mm');
      const fndt = DateTime.fromJSDate(finalDate).toFormat('HH:mm');

      setLoading(true);
      try {
        const { message } = await Api.post('/schedule/create', {
          ...data,
          starts_in: stdt,
          ends_in: fndt,
        });
        successCreateItem(message);
        setLoading(false);
        handleClosed();
        reload();
      } catch ({ message }) {
        setLoading(false);
        showError();
      }
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = value || null;

    setData(cloneData);
  };

  const handleChangeSelect = ({ target: { name, value } }) => {
    setCategoryList(typeof value === 'string' ? value.split(',') : value);
    data[name] = options.indexOf(value);
  };

  const handleChangeMenu = ({ target: { name, value } }) => {
    setMenu(typeof value === 'string' ? value.split(',') : value);
    data[name] = menuListRef.filter((item) => value.includes(item.name));
  };

  return (
    <S.Modal open={open} onClose={handleClosed} fullScreen>
      <DialogTitle>Registrar Agendamento</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 10 }}>
          Adicione apenas promoções dentro do período das 00:00 às 23:59.
        </DialogContentText>
        <form id="formEdit" onSubmit={(event) => create(event, data)}>
          <FormControl fullWidth margin="dense">
            <InputLabel id="day_week_label">Dia da Promoção</InputLabel>
            <Select
              labelId="day_week_label"
              id="day_week"
              name="day_week"
              displayEmpty
              autoFocus
              required
              value={categoryList}
              onChange={handleChangeSelect}
              input={<OutlinedInput label="Dia da Promoção" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem disabled value="">
                <em>Selecione o dia da promoção</em>
              </MenuItem>
              {options.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={{
                    fontWeight:
                      categoryList.indexOf(name) === -1
                        ? theme.typography.fontWeightRegular
                        : theme.typography.fontWeightMedium,
                  }}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            id="discount"
            type="number"
            margin="dense"
            required
            label="Desconto %"
            defaultValue={data.discount}
            onChange={handleChange}
            placeholder="Insira o valor da porcentagem"
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileTimePicker
              required
              value={initialDate}
              inputFormat="HH:mm"
              label="Promoção inicializa as:"
              onChange={(currentDate) => setInitialDate(currentDate)}
              renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileTimePicker
              required
              value={finalDate}
              inputFormat="HH:mm"
              label="Promoção finaliza as:"
              onChange={(currentDate) => setFinalDate(currentDate)}
              renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
            />
          </LocalizationProvider>

          <FormControl fullWidth margin="dense">
            <InputLabel id="promotional_menu_label">Menu Promocional</InputLabel>
            <Select
              labelId="promotional_menu_label"
              id="promotional_menu"
              name="promotional_menu"
              multiple
              required
              value={menu}
              onChange={handleChangeMenu}
              input={<OutlinedInput label="Menu Promocional" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              {menuList.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={menu.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            multiline
            rows={3}
            type="text"
            id="comments"
            margin="dense"
            fullWidth
            label="Observações"
            onChange={handleChange}
            defaultValue={data.comments}
            placeholder="Observações"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClosed}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="formEdit"
          endIcon={loading ? <MaterialIcon name="check" /> : null}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {loading ? 'Registrando...' : 'Registrar'}
        </LoadingButton>
      </DialogActions>
    </S.Modal>
  );
}
