import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
  TextField,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import React from 'react';
import InputMask from 'react-input-mask';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';
import { deepClone, first, onlyNumbers } from '../../../../../functions';

import * as S from './Styles';

export default function Create({
  open,
  data: initialData,
  handleClosed,
  showError,
  updateItem,
}) {
  const [data, setData] = React.useState(initialData);

  const [loading, setLoading] = React.useState(false);
  const [companyError, setCompanyError] = React.useState(false);

  const [menu, setMenu] = React.useState([]);
  const [menuList, setMenuList] = React.useState([]);
  const [menuListRef, setMenuListRef] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await Api.get('/access-level/list');

      setMenu([data?.level_access?.access_name] || []);
      setMenuList(result.map((e) => e.access_name));
      setMenuListRef(result);
    };

    fetchData();
  }, [data]);

  const create = async (event, data) => {
    event.preventDefault();
    setLoading(true);
    try {
      let document = null;

      try {
        const { _id } = await Api.get(`/company/find?document=${data.companie}`);
        document = _id;
        setCompanyError(false);
      } catch (error) {
        setCompanyError(true);
        setLoading(false);
      }

      const bodyData = {
        companie: document,
        fullname: data.fullname,
        document: onlyNumbers(data.document),
        office: data.office,
        comments: data.comments,
        status: data.status,
        birth_date: data.birth_date,
        level_access: data.level_access._id,
        email: String(data.email).toLowerCase(),
      };

      const result = await Api.post(`/employee/edit?id=${data._id}`, bodyData);
      updateItem(result);
      setLoading(false);
      handleClosed();
    } catch ({ message }) {
      setLoading(false);
      showError(message);
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = value || null;

    setData(cloneData);
  };

  const handleChangeMenu = ({ target: { name, value } }) => {
    const cloneData = deepClone(data);

    const nameList = menuListRef.filter(({ access_name }) => access_name === value);

    if (first(nameList)) {
      cloneData[name] = first(nameList);
    }

    setData(cloneData);
    setMenu(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <S.Modal open={open} onClose={handleClosed} fullScreen>
      <DialogTitle>Editar Funcionário</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Preencha os campos abaixo para atualizar os dados do funcionário.
        </DialogContentText>
        <form id="formEdit" onSubmit={(event) => create(event, data)}>
          <TextField
            autoFocus
            fullWidth
            id="companie"
            type="number"
            error={companyError}
            helperText={companyError ? 'Empresa não localizada' : null}
            margin="dense"
            required
            label="Empresa Responsável"
            defaultValue={data.companie}
            onChange={handleChange}
            placeholder="CNPJ ou CPF da empresa responsável"
          />

          <TextField
            type="text"
            id="fullname"
            margin="dense"
            required
            fullWidth
            label="Nome Completo"
            onChange={handleChange}
            defaultValue={data.fullname}
            placeholder="Nome completo do funcionário"
          />

          <InputMask mask="999.999.999-99" value={data.document} onChange={handleChange}>
            {() => (
              <TextField
                id="document"
                margin="dense"
                required
                fullWidth
                label="CPF"
                placeholder="CPF do funcionário"
              />
            )}
          </InputMask>

          <TextField
            type="text"
            id="office"
            margin="dense"
            required
            fullWidth
            label="Setor"
            onChange={handleChange}
            defaultValue={data.office}
            placeholder="Administrativo, Cozinha, Garçom e etc..."
          />

          <InputMask mask="99/99/9999" value={data.birth_date} onChange={handleChange}>
            {() => (
              <TextField
                id="birth_date"
                margin="dense"
                required
                fullWidth
                label="Data de Nascimento"
                placeholder="Administrativo, Cozinha, Garçom e etc..."
              />
            )}
          </InputMask>

          <TextField
            type="email"
            id="email"
            margin="dense"
            required
            fullWidth
            label="Email do funcionário"
            onChange={handleChange}
            defaultValue={data.email}
            placeholder="Email para acessar o sistema"
          />

          <FormControl fullWidth margin="dense">
            <InputLabel id="level_access_label">Nível de Acesso</InputLabel>
            <Select
              labelId="level_access_label"
              id="level_access"
              name="level_access"
              required
              value={menu}
              onChange={handleChangeMenu}
              input={<OutlinedInput label="Nível de Acesso" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              {menuList.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={menu.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            multiline
            rows={3}
            type="text"
            id="comments"
            margin="dense"
            fullWidth
            label="Observações"
            onChange={handleChange}
            defaultValue={data.comments}
            placeholder="Observações"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClosed}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="formEdit"
          endIcon={loading ? <MaterialIcon name="check" /> : null}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {!loading ? 'Atualizar' : 'Atualizando...'}
        </LoadingButton>
      </DialogActions>
    </S.Modal>
  );
}
