import { LoadingButton } from '@mui/lab';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import React from 'react';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';
import { deepClone } from '../../../../../functions';

import * as S from './Styles';

const initialData = {
  companie_name: '',
  active: true,
  document: '',
};

export default function Create({
  open,
  successCreateItem,
  handleClosed,
  showError,
  reload,
}) {
  const [data, setData] = React.useState(initialData);

  const [loading, setLoading] = React.useState(false);

  const create = async (event, data) => {
    event.preventDefault();
    setLoading(true);
    try {
      const bodyData = {
        companie_name: data.companie_name,
        active: data.active,
        document: data.document,
      };

      const { message } = await Api.post('/company/create', bodyData);
      successCreateItem(message);
      setLoading(false);
      handleClosed();
      reload();
    } catch ({ message }) {
      setLoading(false);
      showError(message);
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = value || null;

    setData(cloneData);
  };

  const handleCheck = ({ target: { id, checked } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = checked;

    setData(cloneData);
  };

  return (
    <S.Modal open={open} onClose={handleClosed} fullScreen>
      <DialogTitle>Registrar Empresa</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Preencha os campos abaixo para registrar uma nova empresa.
        </DialogContentText>
        <form id="formEdit" onSubmit={(event) => create(event, data)}>
          <TextField
            autoFocus
            fullWidth
            id="companie_name"
            type="text"
            margin="dense"
            required
            label="Nome da Empresa"
            defaultValue={data.companie_name}
            onChange={handleChange}
            placeholder="Razão social"
          />

          <TextField
            type="text"
            id="document"
            margin="dense"
            required
            fullWidth
            label="CNPJ/CPF"
            onChange={handleChange}
            defaultValue={data.document}
            placeholder="Documento de registro da empresa"
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch id="active" onChange={handleCheck} defaultChecked={data.active} />
              }
              label="Habilitar/Desabilitar"
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClosed}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="formEdit"
          endIcon={loading ? <MaterialIcon name="check" /> : null}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {loading ? 'Adicionando...' : 'Adicionar'}
        </LoadingButton>
      </DialogActions>
    </S.Modal>
  );
}
