import { Box, CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const TextLoading = styled.h4`
  font-weight: normal;
`;

export const Spinner = styled(CircularProgress)`
  margin-bottom: 30px;
`;
