import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Pagination, Paper, Typography } from '@mui/material';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import noImage from '../../../assets/Images/no-image.png';
import Alerts from '../../../components/Alert';
import Loading from '../../../components/Loading';
import MaterialIcon from '../../../components/MaterialIcon';
import Api from '../../../connections/Api';
import { deepClone, generateKey, replaceSourcePath } from '../../../functions';
import DataEmpty from './Components/DataEmpty';
import CreateProduct from './Components/ModalProduct/create';
import EditProduct from './Components/ModalProduct/edit';

import * as S from './Styles';

const Message = withReactContent(Swal);

const messageSettings = {
  title: `Deletar este item?`,
  text: 'Lembre-se, esta ação não poderá ser desfeita.',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText: 'Cancelar',
  confirmButtonText: 'Sim, quero deletar!',
  reverseButtons: true,
};

export default function Menu() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [totalItens, setTotalItens] = React.useState(0);
  const [alert, setAlert] = React.useState({ open: false, message: '' });
  const [showCreateProduct, setShowCreateProduct] = React.useState(false);
  const [showEditProduct, setShowEditProduct] = React.useState({
    status: false,
    data: {},
  });

  const handleClose = () => {
    window.location.reload();
  };

  const confirmDeleteItem = async (id) =>
    Message.fire(messageSettings).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const { message } = await Api.delete(`/menu/delete?id=${id}`);
          setAlert({ ...alert, open: true, status: 'success', message });
          window.location.reload();
        } catch {
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message: 'Não foi possível executar essa ação',
          });
        }
      }
    });

  const renderItemCard = (data) =>
    data.map(
      ({
        _id,
        name,
        category,
        description,
        price,
        price_of,
        image,
        combo,
        combos_released,
      }) => (
        <Grid item xs={12} sm={12} md={3} key={generateKey()}>
          <Paper
            sx={{
              p: 2,
              my: 1,
              mx: 'auto',
              flexGrow: 1,
              maxWidth: 500,
              margin: 'auto',
            }}
          >
            <Grid item container>
              <Grid item container spacing={2} wrap="nowrap" direction="column">
                {!!image && (
                  <Grid
                    container
                    item
                    sx={{ width: '100%', height: 150 }}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      margin: '8px',
                      backgroundImage: `url(${replaceSourcePath(image)})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                )}

                {!image && (
                  <Grid
                    container
                    item
                    sx={{ width: '100%', height: 150 }}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      margin: '8px',
                      backgroundImage: `url(${noImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                )}

                <Grid item>
                  <Grid container spacing={1} direction="row">
                    <Grid item>
                      <MaterialIcon name="menu_book" />
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                        }}
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        {name}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography variant="body2" color="#964b00">
                    {category}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {description || 'Sem descrição adicional'}
                  </Typography>
                </Grid>

                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color={price_of ? 'gray' : ''}
                        style={{
                          textDecoration: price_of ? 'line-through' : 'none',
                        }}
                        component="div"
                      >
                        {price?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" component="div">
                        {price_of?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        size="small"
                        color="error"
                        variant="outlined"
                        onClick={() => confirmDeleteItem(_id)}
                      >
                        Excluir
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        color="success"
                        variant="outlined"
                        onClick={() =>
                          setShowEditProduct({
                            ...showEditProduct,
                            status: true,
                            data: {
                              _id,
                              name,
                              category,
                              description,
                              price,
                              price_of,
                              image,
                              combo,
                              combos_released,
                            },
                          })
                        }
                      >
                        Editar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ),
    );

  const showMessageError = (message) => {
    setAlert({
      ...alert,
      open: true,
      status: 'error',
      message:
        message || 'Erro ao realizar esta ação, verifique os dados e tente novamente!',
    });
  };

  const successCreateItem = (message) => {
    setAlert({
      ...alert,
      status: 'success',
      open: true,
      message,
    });
  };

  const goToPage = async (currentPage) => {
    try {
      const result = await Api.get(
        `/menu/list?page=${currentPage}&query=${localStorage.getItem('search') ?? ''}`,
      );
      setData(result.data);
      setTotalItens(result.total);
      setPage(Number(result.page));
    } catch ({ message }) {
      setData([]);
      setAlert({ ...alert, open: true, status: 'error', message });
    }
  };

  const updateItem = (item) => {
    const cloneData = deepClone(data);

    cloneData.forEach(({ _id }, key) => {
      if (_id === item._id) {
        Object.getOwnPropertyNames(item).forEach((element) => {
          cloneData[key][element] = item[element];

          setData(cloneData);
        });

        setAlert({
          ...alert,
          open: true,
          status: 'success',
          message: `O item ${item.name} foi atualizado com sucesso!`,
        });
      }
    });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, total, page } = await Api.get(
          `/menu/list?query=${localStorage.getItem('search') ?? ''}`,
        );
        setData(data);
        setTotalItens(total);
        setPage(Number(page));
      } catch ({ message }) {
        setData([]);
        setAlert({ ...alert, open: true, status: 'error', message });
      }
    };

    fetchData();
  }, []);

  if (data === null) return <Loading />;

  if (data.length === 0)
    return (
      <>
        <DataEmpty showCreateProduct={() => setShowCreateProduct(true)} />
        <CreateProduct
          open={showCreateProduct}
          handleClickOpen={() => setShowCreateProduct(true)}
          handleClosed={() => setShowCreateProduct(false)}
          successCreateItem={(message) => successCreateItem(message)}
          showError={showMessageError}
          reload={() => window.location.reload()}
        />
      </>
    );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Alerts
        open={alert.open}
        handleClose={handleClose}
        message={alert.message}
        status={alert.status}
      />

      {showCreateProduct && (
        <CreateProduct
          open={showCreateProduct}
          handleClickOpen={() => setShowCreateProduct(true)}
          handleClosed={() => setShowCreateProduct(false)}
          successCreateItem={(message) => successCreateItem(message)}
          showError={showMessageError}
          reload={() => window.location.reload()}
        />
      )}

      {showEditProduct.status && (
        <EditProduct
          open={showEditProduct.status}
          handleClickOpen={() => setShowEditProduct({ ...showEditProduct, status: true })}
          handleClosed={() => setShowEditProduct({ ...showEditProduct, status: false })}
          data={showEditProduct.data}
          updateItem={(item) => updateItem(item)}
          showError={showMessageError}
        />
      )}

      <Grid container spacing={{ xs: 2, md: 2 }}>
        {renderItemCard(data)}
      </Grid>
      <S.AddButton color="primary" onClick={() => setShowCreateProduct(true)}>
        <AddIcon />
      </S.AddButton>
      <S.Pagination>
        <Pagination
          color="primary"
          page={page + 1}
          count={Math.ceil(totalItens / 12)}
          onChange={(event, currentPage) => goToPage(currentPage - 1)}
        />
      </S.Pagination>
    </Box>
  );
}
