import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { useState } from 'react';
import Alerts from '../../../components/Alert';
import Api from '../../../connections/Api';

import * as S from './Styles';

function ChangePassword({ history }) {
  const [alertMessage, setAlert] = useState({ open: false, message: '' });

  const [values, setValues] = React.useState({
    current_password: '',
    new_password: '',
    showCurrentPassword: false,
    showNewPassword: false,
  });

  const handleClickShowCurrentPassword = () => {
    setValues({
      ...values,
      showCurrentPassword: !values.showCurrentPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user_id = localStorage.getItem('user_id');
    const data = new FormData(event.currentTarget);

    const current_password = data.get('current_password');
    const new_password = data.get('new_password');

    if (current_password && new_password) {
      try {
        const { message } = await Api.post('/user/change-password', {
          current_password,
          new_password,
          user_id,
        });

        setAlert({ ...alertMessage, open: true, status: 'success', message });

        setTimeout(() => {
          localStorage.clear();
          history.push('/login');
        }, 1000);
      } catch ({ message }) {
        setAlert({ ...alertMessage, open: true, status: 'error', message });
      }
    } else {
      setAlert({
        ...alertMessage,
        open: true,
        status: 'error',
        message: 'Preencha os campos vazios',
      });
    }
  };

  const handleClose = () => setAlert({ ...alertMessage, open: false });

  const clearQueue = async () => {
    const { message } = await Api.get('/clear-queue');

    alert(message);
  };

  return (
    <S.Wrapper>
      <Alerts
        open={alertMessage.open}
        status={alertMessage.status}
        message={alertMessage.message}
        handleClose={handleClose}
      />
      <S.Row container spacing={4}>
        <S.Colum item xs={12} md={4}>
          <h2>Alterar Senha</h2>
          <p>Lembre-se, é possível recuperar a senha via email na tela de login.</p>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <FormControl sx={{ mt: 3, mb: 2 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-current-password">
                Senha atual
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-current-password"
                name="current_password"
                value={values.current_password}
                onChange={handleChange('current_password')}
                type={values.showCurrentPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="current_password"
              />
            </FormControl>
            <FormControl sx={{ mt: 3, mb: 2 }} fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-new-password">
                Nova senha
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-new-password"
                name="new_password"
                value={values.new_password}
                onChange={handleChange('new_password')}
                type={values.showNewPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle new_password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="new_password"
              />
            </FormControl>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Alterar Senha
            </Button>
          </Box>
        </S.Colum>

        <S.Divider item xs={1} md={1} />

        <S.Colum item xs={12} md={4}>
          <h2>Gerenciamento da fila de impressões</h2>
          <p>Utilize o botão abaixo para limpar a fila de impressões.</p>
          <Button onClick={() => clearQueue()} variant="contained" sx={{ mt: 3, mb: 2 }}>
            Limpar fila
          </Button>
        </S.Colum>
      </S.Row>
    </S.Wrapper>
  );
}

export default ChangePassword;
