import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Pagination, Paper, Typography } from '@mui/material';
import React from 'react';
import Alerts from '../../../components/Alert';
import Loading from '../../../components/Loading';
import MaterialIcon from '../../../components/MaterialIcon';
import Api from '../../../connections/Api';
import { deepClone, generateKey } from '../../../functions';
import DataEmpty from './Components/DataEmpty';
import Create from './Components/ModalProduct/create';
import Edit from './Components/ModalProduct/edit';

import * as S from './Styles';

export default function Company() {
  const [data, setData] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState({
    status: false,
    data: {},
  });
  const [totalItens, setTotalItens] = React.useState(0);
  const [alert, setAlert] = React.useState({ open: false, message: '' });

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  const renderItemCard = (data) =>
    data.map(({ _id, companie_name, active, document }) => (
      <Grid item xs={12} sm={12} md={3} key={generateKey()}>
        <Paper
          sx={{
            p: 2,
            my: 1,
            mx: 'auto',
            flexGrow: 1,
            maxWidth: 500,
            margin: 'auto',
          }}
        >
          <Grid item container>
            <Grid xs item container spacing={2} wrap="nowrap" direction="column">
              <Grid item>
                <Grid container spacing={1} direction="row">
                  <Grid item>
                    <MaterialIcon name="store" />
                  </Grid>
                  <Grid item>
                    {' '}
                    <Typography
                      style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      {companie_name}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography variant="body2" color="#964b00">
                  {document}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ color: !active ? 'gray' : 'black' }}
                >
                  {active ? 'Ativa' : 'Inativa'}
                </Typography>
              </Grid>

              <Grid item xs>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      size="small"
                      color="success"
                      variant="outlined"
                      onClick={() =>
                        setShowEdit({
                          ...showEdit,
                          status: true,
                          data: {
                            _id,
                            companie_name,
                            active,
                            document,
                          },
                        })
                      }
                    >
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    ));

  const showMessageError = (message) => {
    setAlert({
      ...alert,
      open: true,
      status: 'error',
      message:
        message || 'Erro ao realizar esta ação, verifique os dados e tente novamente!',
    });
  };

  const successCreateItem = (message) => {
    setAlert({
      ...alert,
      status: 'success',
      open: true,
      message,
    });
  };

  const goToPage = async (currentPage) => {
    try {
      const result = await Api.get(`/company/list?page=${currentPage}`);

      console.log(result);
      setData(result.data);
      setTotalItens(result.total);
      setPage(Number(result.page));
    } catch ({ message }) {
      setData([]);
      setAlert({ ...alert, open: true, status: 'error', message });
    }
  };

  const updateItem = (item) => {
    const cloneData = deepClone(data);

    cloneData.forEach(({ _id }, key) => {
      if (_id === item._id) {
        Object.getOwnPropertyNames(item).forEach((element) => {
          cloneData[key][element] = item[element];

          setData(cloneData);
        });

        setAlert({
          ...alert,
          open: true,
          status: 'success',
          message: `O item ${item.companie_name} foi atualizado com sucesso!`,
        });
      }
    });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, total, page } = await Api.get('/company/list');
        setData(data);
        setTotalItens(total);
        setPage(Number(page));
      } catch ({ message }) {
        setData([]);
        setAlert({ ...alert, open: true, status: 'error', message });
      }
    };

    fetchData();
  }, []);

  if (data === null) return <Loading />;

  if (data.length === 0)
    return (
      <>
        <DataEmpty showCreate={() => setShowCreate(true)} />
        <Create
          open={showCreate}
          handleClickOpen={() => setShowCreate(true)}
          handleClosed={() => setShowCreate(false)}
          successCreateItem={(message) => successCreateItem(message)}
          showError={showMessageError}
          reload={() => window.location.reload()}
        />
      </>
    );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Create
        open={showCreate}
        handleClickOpen={() => setShowCreate(true)}
        handleClosed={() => setShowCreate(false)}
        successCreateItem={(message) => successCreateItem(message)}
        showError={showMessageError}
        reload={() => window.location.reload()}
      />
      <Edit
        open={showEdit.status}
        handleClickOpen={() => setShowEdit({ ...showEdit, status: true })}
        handleClosed={() => setShowEdit({ ...showEdit, status: false })}
        data={showEdit.data}
        updateItem={(item) => updateItem(item)}
        showError={showMessageError}
      />
      <Alerts
        open={alert.open}
        handleClose={handleClose}
        message={alert.message}
        status={alert.status}
      />
      <Grid container spacing={{ xs: 2, md: 2 }}>
        {renderItemCard(data)}
      </Grid>
      <S.AddButton color="primary" onClick={() => setShowCreate(true)}>
        <AddIcon />
      </S.AddButton>
      <S.Pagination>
        <Pagination
          color="primary"
          page={page + 1}
          count={Math.ceil(totalItens / 12)}
          onChange={(event, currentPage) => goToPage(currentPage - 1)}
        />
      </S.Pagination>
    </Box>
  );
}
