import { styled } from '@mui/system';

export const Divider = styled('div')`
  width: 100%;
  height: 1px;

  margin-top: 10px;
  margin-bottom: 10px;

  border: 1px solid #e0e0e0;
`;
