import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Paper)`
  padding: 15px;
`;

export const Container = styled(Grid)``;

export const Row = styled(Grid)`
  margin-right: 20px;
`;

export const Colum = styled(Grid)``;

export const Divider = styled(Grid)`
  margin: 20px;
`;
