import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Divider = styled('div')`
  width: 20px;
`;

export const Image = styled('img')`
  margin: auto;
  display: flex;
  max-width: 100%;
  max-height: 100%;
`;

export const Row = styled(Grid)``;
