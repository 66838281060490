import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alerts from '../../../components/Alert';
import Loading from '../../../components/Loading';
import MaterialIcon from '../../../components/MaterialIcon';
import Api from '../../../connections/Api';
import { generateKey } from '../../../functions';
import DataEmpty from './Components/DataEmpty';
import Create from './Components/ModalProduct/create';

import * as S from './Styles';

const Message = withReactContent(Swal);

export default function Schedule() {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [totalItens, setTotalItens] = React.useState(0);
  const [showCreate, setShowCreate] = React.useState(false);
  const [alert, setAlert] = React.useState({ open: false, message: '' });

  const handleClose = () => setAlert({ ...alert, open: false });

  const messageSettings = {
    title: 'Remover agendamento?',
    text: 'Lembre-se, esta ação não poderá ser desfeita.',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Sim, quero remove-lo',
    reverseButtons: true,
  };

  const deleteSchedule = async (id) =>
    Message.fire(messageSettings).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const { message } = await Api.delete(`/schedule/delete?id=${id}`);
          setAlert({ ...alert, open: true, status: 'success', message });

          window.location.reload();
        } catch {
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message: 'Não foi possível completar esta ação',
          });
        }
      }
    });

  const listDayWeek = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];

  const renderItemCard = (data) =>
    data.map(
      ({ _id, discount, day_week, starts_in, ends_in, comments, promotional_menu }) => (
        <Grid item xs={12} sm={12} md={3} key={generateKey()}>
          <Paper
            sx={{
              p: 2,
              my: 1,
              mx: 'auto',
              flexGrow: 1,
              maxWidth: 500,
              margin: 'auto',
            }}
          >
            <Grid item container>
              <Grid xs item container spacing={2} wrap="nowrap" direction="column">
                <Grid item>
                  <Grid container spacing={1} direction="row">
                    <Grid item>
                      <MaterialIcon name="schedule" />
                    </Grid>
                    <Grid item>
                      {' '}
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                        }}
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        Promoção de {listDayWeek[day_week]} - {discount}%
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="body2" color="#964b00">
                    {listDayWeek[day_week]}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {starts_in} - {ends_in}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" />
                </Grid>

                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="body2" component="div">
                        {comments}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: '5px' }}>
                    <Grid item xs>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <MaterialIcon name="menu_book" />
                          <Typography
                            style={{
                              marginLeft: '10px',
                              justifyContent: 'center',
                              diplay: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            Menu Promocional
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {promotional_menu.map((item) => (
                            <S.MenuItem key={generateKey()}>
                              <MaterialIcon name="local_offer" size={15} />
                              <Typography
                                style={{
                                  marginLeft: '10px',
                                  justifyContent: 'center',
                                  diplay: 'flex',
                                  alignItems: 'center',
                                  fontSize: '14px',
                                }}
                                key={generateKey()}
                                variant="body2"
                              >
                                {item.name}
                              </Typography>
                            </S.MenuItem>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        size="small"
                        color="error"
                        variant="outlined"
                        onClick={() => deleteSchedule(_id)}
                      >
                        Excluir agendamento
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ),
    );

  const showMessageError = (message) => {
    setAlert({
      ...alert,
      open: true,
      status: 'error',
      message:
        message || 'Erro ao realizar esta ação, verifique os dados e tente novamente!',
    });
  };

  const successCreateItem = (message) => {
    setAlert({
      ...alert,
      status: 'success',
      open: true,
      message,
    });
  };

  const goToPage = async (currentPage) => {
    try {
      const result = await Api.get(`/schedule/list?page=${currentPage}`);
      setData(result.data);
      setTotalItens(result.total);
      setPage(Number(result.page));
    } catch ({ message }) {
      setData([]);
      setAlert({ ...alert, open: true, status: 'error', message });
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, total, page } = await Api.get('/schedule/list');
        setData(data);
        setTotalItens(total);
        setPage(Number(page));
      } catch ({ message }) {
        setData([]);
        setAlert({ ...alert, open: true, status: 'error', message });
      }
    };

    fetchData();
  }, []);

  if (data === null) return <Loading />;

  if (data.length === 0)
    return (
      <>
        <DataEmpty showCreate={() => setShowCreate(true)} />
        <Create
          open={showCreate}
          handleClickOpen={() => setShowCreate(true)}
          handleClosed={() => setShowCreate(false)}
          successCreateItem={(message) => successCreateItem(message)}
          showError={(message) => showMessageError(message)}
          reload={() => window.location.reload()}
        />
      </>
    );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Create
        open={showCreate}
        handleClickOpen={() => setShowCreate(true)}
        handleClosed={() => setShowCreate(false)}
        successCreateItem={(message) => successCreateItem(message)}
        showError={showMessageError}
        reload={() => window.location.reload()}
      />
      <Alerts
        open={alert.open}
        handleClose={handleClose}
        message={alert.message}
        status={alert.status}
      />
      <Grid container spacing={{ xs: 2, md: 2 }}>
        {renderItemCard(data)}
      </Grid>
      <S.AddButton color="primary" onClick={() => setShowCreate(true)}>
        <AddIcon />
      </S.AddButton>
      <S.Pagination>
        <Pagination
          color="primary"
          page={page + 1}
          count={Math.ceil(totalItens / 12)}
          onChange={(event, currentPage) => goToPage(currentPage - 1)}
        />
      </S.Pagination>
    </Box>
  );
}
