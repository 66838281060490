import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Paper)`
  padding: 15px;
`;

export const Row = styled(Grid)``;

export const Colum = styled(Grid)``;

export const Divider = styled(Grid)``;
