import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { GlobalStyle } from '../assets/Styles';

import NotFound from '../components/404';
import Login from '../pages/Authentications';
import Model from '../themes';

import { getTokenAccess } from '../functions';

export default function Routes() {
  function PrivateRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          getTokenAccess() ? (
            <Component {...props} component={Component} />
          ) : (
            <Redirect to={{ pathname: '/404', state: { from: props.location } }} />
          )
        }
      />
    );
  }

  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" exact component={Login} />
          <Route path="/404" exact component={NotFound} />

          <PrivateRoute
            path="/sistema/:pages"
            exact
            component={(props) => <Model {...props} />}
          />

          <Redirect path="*" to="/404" />
        </Switch>
      </Router>
    </>
  );
}
