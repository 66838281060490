import Box from '@mui/material/Box';
import styled from 'styled-components';

export const Wrapper = styled(Box)``;

export const ButtonReport = styled.div`
  width: 100%;
  bottom: 0px;
  position: absolute;
`;
