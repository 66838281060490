import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React from 'react';
import Alerts from '../../../components/Alert';
import MaterialIcon from '../../../components/MaterialIcon';
import Api from '../../../connections/Api';
import Edit from './Components/Modals/edit';
import DataEmpty from './DataEmpty';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DateTime } from 'luxon';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deepClone } from '../../../functions';
import * as S from './Styles';

const defaultMessageError =
  'Erro ao realizar esta ação, verifique os dados e tente novamente!';

const Message = withReactContent(Swal);

const reopenMessage = {
  title: `Reabrir comanda?`,
  icon: 'alert',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText: 'Voltar',
  confirmButtonText: 'Reabrir comanda',
  reverseButtons: true,
};

const cancelMessage = reopenMessage && {
  title: `Cancelar comanda? Esta ação não poderá ser desfeita!`,
  icon: 'alert',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  cancelButtonText: 'Voltar',
  confirmButtonText: 'Cancelar comanda',
  reverseButtons: true,
};

function ManageHistory({ history }) {
  const theme = useTheme();
  const options = ['Fechadas', 'Canceladas'];

  const [data, setData] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [groupList, setGroupList] = React.useState(['Fechadas']);
  const [finalDate, setFinalDate] = React.useState(new Date());
  const [initialDate, setInitialDate] = React.useState(new Date());
  const [customAlert, setAlert] = React.useState({ open: false, message: '' });

  const [EditOrderModal, setEditOrderModal] = React.useState({
    status: false,
    orderId: null,
  });

  const handleClose = () => setAlert({ ...customAlert, open: false });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      initialDate.setHours(0, 0, 0, 0);
      finalDate.setHours(23, 59, 59, 0);

      const initial = initialDate.getTime();
      const final = finalDate.getTime();

      if (final < initial) {
        setAlert({
          ...customAlert,
          open: true,
          status: 'error',
          message: 'A data final não pode ser menor que a data inicial',
        });
      } else {
        const bodyData = {
          initialDate: initial,
          finalDate: final,
        };

        if (search !== null && search.trim() !== '') {
          bodyData.search = search;
        }

        const filter = groupList[0] === 'Fechadas' ? 1 : 2;
        const data = await Api.post(`/manage-history?status=${filter}`, bodyData);

        if (data.length > 0) {
          const dataResult = data.map(({ _id, ...rest }) => ({ id: _id, ...rest }));

          setData(dataResult);
        } else {
          setData(null);
        }
      }
    } catch ({ message }) {
      setAlert({
        ...customAlert,
        open: true,
        status: 'error',
        message,
      });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridCsvExportMenuItem
          id="export-csv"
          options={{
            fileName: `historico-${DateTime.now().toFormat('dd-MM-yyyy')}`,
            delimiter: ';',
            utf8WithBom: true,
            fields: [
              'id',
              'table',
              'created_at',
              'created_by.name',
              'closed_by.name',
              'internal_request',
              'ordersTotalNum',
            ],
          }}
        />
      </GridToolbarContainer>
    );
  }

  function handleChangeSelect({ target: { value } }) {
    setGroupList(typeof value === 'string' ? value.split(',') : value);

    setData(null);
  }

  const handleChangeSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const closeEditModal = () => {
    setEditOrderModal((current) => ({ ...current, status: false }));
  };

  const showMessageError = (message = defaultMessageError) => {
    setAlert({
      ...alert,
      open: true,
      status: 'error',
      message,
    });
  };

  const reopenOrder = async (orderId) => {
    Message.fire(reopenMessage).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          const { message } = await Api.post(`/order/edit?id=${orderId}`, {
            status: 0,
          });

          setAlert({ ...alert, open: true, status: `success`, message });

          setTimeout(() => history.push('/sistema/dashboard'), 1000);
        } catch {
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message: 'Não foi possível reabrir a comanda, tente novamente!',
          });
        }
      }
    });
  };

  const cancelOrder = async (orderId) => {
    Message.fire(cancelMessage).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          await Api.post(`/order/edit?id=${orderId}`, { status: 2 });

          const cloneData = deepClone(data);
          const index = cloneData.findIndex((order) => order.id === orderId);

          cloneData.splice(index, 1);

          setData(cloneData);
          setAlert({
            ...alert,
            open: true,
            status: 'success',
            message: 'Comanda cancelada com sucesso!',
          });
        } catch {
          setAlert({
            ...alert,
            open: true,
            status: 'error',
            message: 'Não foi possível cancelar esta comanda, tente novamente!',
          });
        }
      }
    });
  };

  const editOrder = (orderId) => {
    setEditOrderModal((current) => ({ ...current, orderId, status: true }));
  };

  function renderTable() {
    return (
      <S.Container style={{ height: '100%' }}>
        <DataGrid
          columns={[
            {
              field: 'id',
              headerName: 'Nº da comanda',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              valueGetter: (params) => params.row.id.slice(0, 8),
            },
            {
              field: 'table',
              headerName: 'Mesa',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'created_at',
              headerName: 'Comanda aberta em:',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              valueGetter: (params) =>
                DateTime.fromMillis(params.row.created_at).toFormat(
                  'dd/MM/yyyy HH:mm:ss',
                ),
            },
            {
              field: 'created_by.name',
              headerName: 'Criada por:',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              valueGetter: (params) => params.row.created_by.name,
            },
            {
              field: 'closed_by.name',
              headerName: 'Fechada por:',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              valueGetter: (params) => params.row.closed_by?.name || 'Comanda cancelada',
            },
            {
              field: 'internal_request',
              headerName: 'Destino da comanda',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              valueGetter: (params) =>
                params.row.internal_request ? 'Comanda interna' : 'Comanda externa',
            },
            {
              field: 'actions',
              headerName: 'Ações',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              renderCell: (params) => (
                <div>
                  <Button
                    title="Editar Comanda"
                    color="primary"
                    size="small"
                    onClick={() => editOrder(params.row.id)}
                  >
                    <MaterialIcon name="edit_outlined" style={{ width: '24px' }} />
                  </Button>
                  <Button
                    title="Reabrir Comanda"
                    color="primary"
                    size="small"
                    onClick={() => reopenOrder(params.row.id)}
                  >
                    <MaterialIcon
                      name="file_open_outlined"
                      size={24}
                      style={{ width: '24px' }}
                    />
                  </Button>

                  {groupList[0] === 'Fechadas' && (
                    <Button
                      title="Cancelar Comanda"
                      color="primary"
                      size="small"
                      onClick={() => cancelOrder(params.row.id)}
                    >
                      <MaterialIcon
                        name="do_disturb_outlined"
                        size={24}
                        style={{ width: '24px' }}
                      />
                    </Button>
                  )}
                </div>
              ),
            },
          ]}
          rows={data}
          autoHeight
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          localeText={{ toolbarExportCSV: 'Exportar relatório' }}
        />
      </S.Container>
    );
  }

  return (
    <>
      <S.Wrapper onSubmit={handleSubmit} component="form" xs={12} sm={3} md={2}>
        <Alerts
          open={customAlert.open}
          status={customAlert.status}
          message={customAlert.message}
          handleClose={handleClose}
        />

        {EditOrderModal.status && (
          <Edit
            open={EditOrderModal.status}
            orderId={EditOrderModal.orderId}
            showError={showMessageError}
            handleClosed={closeEditModal}
          />
        )}

        <h2>Gerir Histórico</h2>
        <p
          style={{
            fontSize: '14px',
            marginTop: '5px',
            marginBottom: '15px',
          }}
        >
          Esse painel permite que você manipule comandas já fechadas ou canceladas.
        </p>
        <S.Container container>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={initialDate}
                inputFormat="dd/MM/yyyy"
                label="Período inicial"
                onChange={(currentDate) => setInitialDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={finalDate}
                inputFormat="dd/MM/yyyy"
                label="Período final"
                onChange={(currentDate) => setFinalDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <FormControl margin="dense" style={{ width: 180 }}>
              <InputLabel id="group-label">Agrupar por comandas:</InputLabel>
              <Select
                labelId="group-label"
                id="group"
                name="group"
                required
                value={groupList}
                onChange={(event) => handleChangeSelect(event)}
                input={<OutlinedInput label="Agrupar por comandas:" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 180,
                    },
                  },
                }}
              >
                {options.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{
                      fontWeight:
                        groupList.indexOf(name) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.Row>
          <S.Row item>
            <TextField
              type="text"
              name="search"
              fullWidth
              label="Nome da comanda"
              margin="dense"
              onChange={handleChangeSearch}
              placeholder="Nome da comanda"
            />
          </S.Row>
          <S.Row item>
            <Button
              type="submit"
              variant="contained"
              style={{ height: 55, marginTop: 8 }}
            >
              Consultar
            </Button>
          </S.Row>
        </S.Container>
      </S.Wrapper>
      <S.Divider />

      {data === null ? <DataEmpty /> : renderTable()}
    </>
  );
}

export default ManageHistory;
