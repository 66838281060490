import * as S from './Styles';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import Loading from '../../../../../components/Loading';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  deepClone,
  generateKey,
  not,
  orderByGroupName,
  orderByName,
} from '../../../../../functions';

const filter = createFilterOptions();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Edit({ open, orderId, showError, handleClosed }) {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const [menuList, setMenuList] = React.useState([]);
  const [menuListRef, setMenuListRef] = React.useState([]);
  const [ordersList, setOrdersList] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [optionsData, setOptions] = React.useState([]);
  const [awaiting, setAwaiting] = React.useState(true);
  const [originalData, setOriginalData] = React.useState([]);
  const [internalRequest, setInternalRequest] = React.useState(false);

  const isAdmin = () => localStorage.getItem('user_level_name') === 'Administrador';

  React.useEffect(() => {
    const controller = new AbortController();

    setAwaiting(true);

    const handleFetch = async () => {
      try {
        await Promise.all([
          Api.get(`/order/find?id=${orderId}`, { signal: controller.signal }),
          Api.get('/menu/custom', { signal: controller.signal }),
          Api.get('/order/list', { signal: controller.signal }),
        ]).then(([orderData, result, resultData]) => {
          const organizedResult = result.sort(orderByName);
          const organizedResultData = resultData.data
            .filter((item) => 'order_group' in item)
            .filter(({ order_group }) => order_group !== null)
            .sort(orderByGroupName);
          const itemsSelectedName = orderData?.orders?.map(
            ({ name: itemName }) => itemName,
          );
          const notFoundItems = itemsSelectedName
            .map((item) =>
              organizedResult.map((e) => e.name)?.includes(item) ? undefined : item,
            )
            .filter(Boolean);

          orderData?.orders?.forEach((item) => {
            if (notFoundItems?.includes(item.name)) {
              organizedResult?.push(item);
            }
          });

          setOriginalData(orderData?.orders?.map(({ name }) => name));
          setInternalRequest(orderData.internal_request);
          setMenuListRef(organizedResult);
          setValue({ title: orderData?.order_group ?? '' });
          setMenuList(organizedResult.map((e) => e.name));
          setMenu(orderData?.orders?.map((item) => item.name) || []);
          setOrdersList(orderData?.orders || []);
          setOptions(
            [...new Set(organizedResultData.map(({ order_group }) => order_group))].map(
              (title) => ({ title }),
            ),
          );
          setData(orderData);
        });

        setTimeout(() => setAwaiting(false), 1000);
      } catch (error) {
        setAwaiting(false);
      }
    };

    handleFetch();

    return () => {
      controller.abort();
    };
  }, []);

  const editOder = async (event, data) => {
    event.preventDefault();

    setLoading(true);

    const error = [];

    const includes = originalData.filter((item) => menu.includes(item));

    if (originalData.length === includes.length || isAdmin()) {
      ordersList.forEach((item) => {
        if ('selecteds' in item) {
          const comboValues = item.selecteds
            .map((item) => item.amount)
            .reduce((total, curr) => total + curr, 0);

          if (comboValues > item.amount * item.combos_released) {
            error.push(
              `O item ${item.name} só permite ${
                item.amount * item.combos_released
              } itens no combo.`,
            );
          }
        }
      });

      if (error.length > 0) {
        setLoading(false);
        return showError(error[0]);
      }

      const invalidAmount = ordersList.filter(
        ({ amount }) => amount === null || amount === undefined,
      );

      if (invalidAmount.length) {
        setLoading(false);
        return showError(
          `A quantidade dos itens ${invalidAmount
            .map(({ name }) => name)
            .join(', ')} é inferior a 0`,
        );
      }

      try {
        const bodyData = {
          note: data.note,
          table: data.table,
          orders: ordersList,
          order_group: data.order_group,
          clients_data: [],
        };

        await Api.post(`/order/edit?id=${data._id}`, bodyData);

        window.location.reload();
      } catch ({ message }) {
        setLoading(false);
        return showError(message);
      }
    } else {
      return showError('Você não tem permissão para remover itens do cardápio!');
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = value || null;

    setData(cloneData);
  };

  const handleChangeMenu = ({ name, value }) => {
    const order = deepClone(data);

    setMenu(typeof value === 'string' ? value.split(',') : value);

    const itemsIncludedOnMenu = menuListRef.filter((item) => value.includes(item.name));

    order[name] = itemsIncludedOnMenu;

    setData(order);

    const listItens = itemsIncludedOnMenu.map((item) => {
      const orderItem = order.orders.find((order) => order.name === item.name);
      const menuItem = menuListRef.find((menu) => menu.name === item.name);
      const selectedItem = orderItem || menuItem;

      return {
        ...item,
        to: null,
        ready: selectedItem?.ready || false,
        amount: selectedItem?.amount || 1,
        price: selectedItem?.price,
        ...(selectedItem?.selecteds && { selecteds: selectedItem.selecteds }),
      };
    });

    setOrdersList(listItens);
  };

  const handleChangeCombo = (value, order, key) => {
    const orderClone = deepClone(ordersList);

    if (not('selecteds' in order)) {
      order.selecteds = order.combo.filter((item) => value.includes(item.name));
    } else {
      const selectedCombosMap = new Map(
        order.selecteds.map(({ name, amount }) => [name, amount]),
      );

      order.selecteds = order.combo
        .filter((item) => value.includes(item.name))
        .map((item) => ({
          ...item,
          amount: selectedCombosMap.get(item.name) || item.amount,
        }));
    }

    orderClone[key] = order;

    setOrdersList(orderClone);
  };

  const handleAddAmount = (id, value, order) => {
    const list = deepClone(ordersList);

    value++;

    list.forEach((item, index) => {
      if (item.name === id) {
        if ('combo' in order && order.combo.length > 0) {
          const newObject = deepClone(order);

          newObject.selecteds = [];

          if (order.name.includes(' Nº')) {
            const names = order.name.split(' Nº');
            newObject.name = `${names[0]} Nº${+names[1] + 1}`;
          }

          newObject.combo.forEach((item, key) => {
            newObject.combo[key].amount = 1;
          });

          menu.push(newObject.name);
          list.push(newObject);
          menuListRef.push(newObject);

          setMenu(deepClone(menu));
          setMenuList(deepClone(menuListRef).map(({ name }) => name));
          setMenuListRef(deepClone(menuListRef).sort(orderByName));
        } else {
          list[index].amount = +value;
        }
      }
    });

    setOrdersList(list);
  };

  const handleRemoveAmount = (id, value, order) => {
    const list = deepClone(ordersList);

    if (value > 1) {
      value--;
    }

    list.forEach((item, index) => {
      if (item.name === id) {
        if ('combo' in order && order.combo.length > 0) {
          list.splice(index, 1);

          const menuListRefIndex = menuListRef
            .map((listRef, keyIndex) =>
              listRef.name === item.name ? keyIndex : undefined,
            )
            .filter(Boolean)[0];

          menu.splice(menu.indexOf(item.name), 1);
          menuListRef.splice(menuListRefIndex, 1);
          menuList.splice(menuList.indexOf(item.name), 1);

          setMenu(deepClone(menu));
          setMenuList(deepClone(menuListRef).map(({ name }) => name));
          setMenuListRef(deepClone(menuListRef).sort(orderByName));
        } else {
          list[index].amount = +value;
        }
      }
    });

    setOrdersList(list);
  };

  const handleAddComboAmount = (itemSelected, key, value, keyList) => {
    const list = deepClone(ordersList);

    const comboValues = itemSelected.selecteds
      .map((item) => item.amount)
      .reduce((total, curr) => total + curr, 0);

    if (comboValues < itemSelected.amount * itemSelected.combos_released) {
      value++;

      list[keyList].selecteds[key].amount = value;

      setOrdersList(list);
    }
  };

  const handleRemoveComboAmount = (_itemSelected, key, value, keyList) => {
    const list = deepClone(ordersList);

    value--;

    if (value > 0) {
      list[keyList].selecteds[key].amount = value;

      setOrdersList(list);
    }
  };

  return (
    <Dialog open={open} onClose={handleClosed} fullScreen>
      <DialogTitle fullWidth>COMANDA DA MESA #{data.table}</DialogTitle>
      {awaiting === false ? (
        <>
          <DialogContent fullWidth>
            <DialogContentText style={{ marginBottom: 3 }} fullWidth>
              Edite as informações da comanda.
            </DialogContentText>

            <form id="formEdit" onSubmit={(e) => editOder(e, data)}>
              {!internalRequest && (
                <Autocomplete
                  value={value}
                  margin="dense"
                  fullWidth
                  style={{ marginTop: '5px' }}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string') {
                      setValue({ title: newValue });
                      handleChange({
                        target: { id: 'order_group', value: newValue.title },
                      });
                    } else if (newValue && newValue.inputValue) {
                      setValue({ title: newValue.inputValue });
                      handleChange({
                        target: {
                          id: 'order_group',
                          value: newValue.inputValue,
                        },
                      });
                    } else {
                      setValue(newValue);
                      handleChange({
                        target: { id: 'order_group', value: newValue.title },
                      });
                    }
                  }}
                  filterOptions={(options, params) => {
                    const { inputValue } = params;
                    const filtered = filter(options, params);
                    const isExisting = options.some(
                      (option) => inputValue === option.title,
                    );

                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `criar grupo: "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="order_group"
                  options={optionsData}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  freeSolo
                  renderOption={(props, option) => <li {...props}>{option.title}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grupo"
                      placeholder="Agrupe as comanda por grupo"
                    />
                  )}
                />
              )}

              <TextField
                id="table"
                type="text"
                margin="dense"
                fullWidth
                label="Nome da Comanda"
                onChange={handleChange}
                disabled={internalRequest}
                defaultValue={data?.table}
              />

              <Autocomplete
                id="promotional_menu"
                name="promotional_menu"
                multiple
                options={menuList}
                disableCloseOnSelect
                clearIcon={false}
                value={menu}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(_event, productList) => {
                  const orders = data.orders?.map(({ name }) => name);
                  const includes = orders.filter((item) => productList.includes(item));

                  if (orders.length === includes.length || isAdmin()) {
                    handleChangeMenu({ name: 'promotional_menu', value: productList });
                  } else {
                    showError('Você não tem permissão para remover itens do cardápio!');
                  }
                }}
                style={{ marginTop: '10px' }}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Cardápio" placeholder="Cardápio" />
                )}
              />

              {ordersList?.map((item, keylist) => (
                <React.Fragment key={generateKey()}>
                  <div
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      marginBottom: 8,
                      display: 'flex',
                      marginTop: '10px',
                      flexDirection: 'column',
                      border: 'gray solid 1.5px',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <TextField
                        id="item"
                        type="text"
                        label="Pedido"
                        margin="dense"
                        disabled
                        maxRows={2}
                        multiline
                        fullWidth
                        defaultValue={item.name}
                      />
                      <S.Divider />
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '10px',
                          marginBottom: '10px',
                          justifyContent: 'space-between',
                        }}
                      >
                        {'combo' in item &&
                        item.combo.length > 0 &&
                        item.name.includes(' Nº1') ? null : (
                          <Button
                            variant="contained"
                            style={{ height: 53, width: '40%' }}
                            color="error"
                            onClick={() =>
                              handleRemoveAmount(item.name, item.amount, item)
                            }
                          >
                            -
                          </Button>
                        )}
                        {'combo' in item &&
                        item.combo.length > 0 &&
                        item.name.includes('Nº1') ? null : (
                          <span
                            style={{
                              fontSize: 20,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            {item.amount}
                          </span>
                        )}

                        <Button
                          variant="contained"
                          style={{
                            height: 53,
                            width:
                              'combo' in item &&
                              item.combo.length > 0 &&
                              item.name.includes('Nº1')
                                ? '100%'
                                : '40%',
                          }}
                          color="success"
                          onClick={() => handleAddAmount(item.name, item.amount, item)}
                          disabled={
                            !!ordersList.find(
                              (ordes) =>
                                ordes.name ===
                                `${item.name.split('Nº')[0]}Nº${
                                  +item.name.split('Nº')[1] + 1
                                }`,
                            )
                          }
                        >
                          {'combo' in item && item.combo.length > 0
                            ? `Adicionar combo de ${item.name.split('Nº')[0]}Nº${
                                +item.name.split('Nº')[1] + 1
                              }`
                            : '+'}
                        </Button>
                      </div>
                    </div>
                    {'combo' in item && item.combo.length > 0 && (
                      <>
                        <Autocomplete
                          multiple
                          id="combo"
                          name="combo"
                          clearIcon={false}
                          disableCloseOnSelect
                          style={{ marginTop: '10px' }}
                          getOptionLabel={(option) => option}
                          options={item.combo.map(({ name }) => name)}
                          value={item?.selecteds?.map((item) => item.name) || []}
                          onChange={(_event, value) =>
                            handleChangeCombo(value, item, keylist)
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Selecione até ${item.combos_released} itens`}
                              placeholder={`Selecione até ${item.combos_released} itens`}
                            />
                          )}
                        />

                        {(item?.selecteds || []).map((itemSelected, key) => (
                          <React.Fragment key={generateKey()}>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <TextField
                                id="item"
                                type="text"
                                label="Pedido"
                                margin="dense"
                                disabled
                                maxRows={2}
                                multiline
                                fullWidth
                                defaultValue={itemSelected.name}
                              />
                              <S.Divider />
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  marginTop: '10px',
                                  marginBottom: '10px',
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ height: 53, width: '40%' }}
                                  color="error"
                                  onClick={() =>
                                    handleRemoveComboAmount(
                                      item,
                                      key,
                                      itemSelected.amount,
                                      keylist,
                                    )
                                  }
                                >
                                  -
                                </Button>
                                <span
                                  style={{
                                    fontSize: 20,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {itemSelected.amount}
                                </span>
                                <Button
                                  variant="contained"
                                  style={{ height: 53, width: '40%' }}
                                  color="success"
                                  onClick={() =>
                                    handleAddComboAmount(
                                      item,
                                      key,
                                      itemSelected.amount,
                                      keylist,
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                            <S.Divider />
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </div>
                </React.Fragment>
              ))}

              <TextField
                rows={3}
                id="note"
                type="text"
                margin="dense"
                multiline
                fullWidth
                label="Descrição"
                onChange={handleChange}
                defaultValue={data?.note}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleClosed}>
              Cancelar
            </Button>
            <LoadingButton
              type="submit"
              form="formEdit"
              endIcon={loading ? <MaterialIcon name="check" /> : null}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              {loading ? 'Atualizando...' : 'Atualizar'}
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <Loading message="Carregando informações da comanda..." />
      )}
    </Dialog>
  );
}
