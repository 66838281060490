import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React from 'react';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Alerts from '../../../components/Alert';
import Api from '../../../connections/Api';
import { calculateTotal, moneyFormat, orderByName } from '../../../functions';
import DataEmpty from './DataEmpty';

import MaterialIcon from '../../../components/MaterialIcon';
import { HistoryDetails } from './Components/HistoryDetails';
import * as S from './Styles';

function Challenge() {
  const theme = useTheme();

  const [data, setData] = React.useState(null);
  const [finalDate, setFinalDate] = React.useState(new Date());
  const [initialDate, setInitialDate] = React.useState(new Date());
  const [alert, setAlert] = React.useState({ open: false, message: '' });

  const [open, setOpen] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState(null);

  const [options, setOptions] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [employeeSelected, selectEmployee] = React.useState('');

  const handleClose = () => setAlert({ ...alert, open: false });

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await Api.get('/challenge/able/employees');
      const organizedResult = result.map(({ name }) => name).sort(orderByName);

      setEmployees(result);
      setOptions(organizedResult);
    };

    fetchData();
  }, [data]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      initialDate.setHours(0, 0, 0, 0);
      finalDate.setHours(23, 59, 59, 0);

      const initial = initialDate.getTime();
      const final = finalDate.getTime();

      if (final < initial) {
        setAlert({
          ...alert,
          open: true,
          status: 'error',
          message: 'A data final não pode ser menor que a data inicial',
        });
      } else {
        const bodyData = {
          initialDate: initial,
          finalDate: final,
        };

        const employee = employees.find(({ name }) => name === employeeSelected);

        const { detailedChanges } = await Api.post('/challenge/employee', {
          ...bodyData,
          id: employee._id,
        });

        setData(detailedChanges);
      }
    } catch ({ message }) {
      setAlert({
        ...alert,
        open: true,
        status: 'error',
        message,
      });
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleChangeSelect = ({ target: { value } }) => selectEmployee(value);

  const openHistoryDetailsModal = (item) => {
    setOpen(true);
    setItemSelected(item);
  };

  const closeHistoryDetailsModal = () => {
    setOpen(false);
    setItemSelected(null);
  };

  function renderTable() {
    return (
      <S.Container style={{ height: '100%' }}>
        <DataGrid
          columns={[
            {
              field: 'id',
              headerName: 'Nº da alteração',
              minWidth: 210,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'createdAt',
              headerName: 'Data da alteração',
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'table',
              headerName: 'Nome da comanda',
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
            },
            {
              field: 'changedItems',
              headerName: 'Nº de alterações',
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              renderCell: ({ value }) => value.length,
            },
            {
              field: 'totalChange',
              headerName: 'Valor total',
              minWidth: 150,
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              renderCell: ({ value }) => moneyFormat(value),
            },
            {
              field: 'details',
              headerName: 'Detalhes',
              flex: 1,
              headerAlign: 'center',
              align: 'center',
              renderCell: ({ row: item }) => (
                <Button
                  title="Editar Comanda"
                  color="primary"
                  size="small"
                  onClick={() => openHistoryDetailsModal(item)}
                >
                  <MaterialIcon name="info" style={{ width: '24px' }} />
                </Button>
              ),
            },
          ]}
          rows={data}
          autoHeight
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
        />
      </S.Container>
    );
  }

  return (
    <>
      <S.Wrapper onSubmit={handleSubmit} component="form" xs={12} sm={3} md={2}>
        {open && itemSelected && (
          <HistoryDetails
            open={open}
            historyDetails={itemSelected}
            closeModal={closeHistoryDetailsModal}
          />
        )}
        <Alerts
          open={alert.open}
          status={alert.status}
          message={alert.message}
          handleClose={handleClose}
        />
        <h2 style={{ marginBottom: 10 }}>Desafio do Bagre</h2>
        <S.Container container>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={initialDate}
                inputFormat="dd/MM/yyyy"
                label="Período inicial"
                onChange={(currentDate) => setInitialDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                required
                value={finalDate}
                inputFormat="dd/MM/yyyy"
                label="Período final"
                onChange={(currentDate) => setFinalDate(currentDate)}
                renderInput={(props) => <TextField fullWidth margin="dense" {...props} />}
              />
            </LocalizationProvider>
          </S.Row>
          <S.Row item>
            <FormControl margin="dense" style={{ width: 300 }}>
              <InputLabel id="group-label">Funcionário:</InputLabel>
              <Select
                labelId="group-label"
                id="group"
                name="group"
                required
                value={employeeSelected}
                onChange={handleChangeSelect}
                input={<OutlinedInput label="Funcionário:" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 150,
                    },
                  },
                }}
              >
                {options.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{
                      fontWeight:
                        [employeeSelected].indexOf(name) === -1
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.Row>
          <S.Row item>
            <Button
              type="submit"
              variant="contained"
              style={{ height: 55, marginTop: 8 }}
            >
              Consultar
            </Button>
          </S.Row>
          {data === null ? null : (
            <S.Row style={{ height: 55, marginTop: 8 }}>
              <h3 style={{ fontWeight: 'normal' }}>Total:</h3>{' '}
              <h3>{calculateTotal(data)}</h3>
            </S.Row>
          )}
        </S.Container>
      </S.Wrapper>
      <S.Divider />
      {data === null ? <DataEmpty /> : renderTable()}
    </>
  );
}

export default Challenge;
