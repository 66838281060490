import { styled } from '@mui/system';

export const Icon = styled('span')`
  color: ${({ color }) => color};
  ${({ size }) => `font-size:${size}px` || ''};
`;

export const ReleaseNotesContainer = styled('div')`
  color: #333;
  margin: auto;
  padding: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  ol,
  ul {
    list-style: none;
  }
`;

export const ListItem = styled('li')`
  position: relative;
  line-height: 1.6;
  padding-left: 20px;
  margin-bottom: 15px;

  &::before {
    content: '';
    left: 0;
    top: 50%;
    width: 6px;
    height: 6px;
    position: absolute;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: green;
  }
`;

export const FixesListItem = styled(ListItem)`
  &::before {
    background-color: #dc3545;
  }
`;

export const Title = styled('h2')`
  color: white;
  margin: 0;
  padding: 10px 15px;
  font-size: 1.2em;
  background-color: green;
`;

export const ReleaseNote = styled('div')`
  border: 1px solid #e0e0e0;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: #f9f9f9;
`;

export const Fixes = styled('div')`
  padding: 15px;
`;

export const Releases = styled(Fixes)`
  border-bottom: 1px solid #e0e0e0;
`;

export const ReleasesGroup = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: left;
`;

export const ReleaseTitle = styled('h4')`
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
`;

export const FixesGroup = styled(ReleasesGroup)``;

export const FixeTitle = styled(ReleaseTitle)`
  font-size: 16px;
  font-weight: 500;
`;
