import React from "react";

import * as S from "./Styles";

export default function Loading({ message = null }) {
  return (
    <S.Wrapper
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <S.Spinner sx={{ marginBottom: "30px" }} />
      <S.TextLoading>{message || "Carregando..."}</S.TextLoading>
    </S.Wrapper>
  );
}
