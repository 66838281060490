import AddIcon from '@mui/icons-material/Add';
import React from 'react';

import * as S from './Styles';

export default function DataEmpty({ showCreateProduct }) {
  return (
    <S.Wrapper>
      <S.TextLoading>Nenhum registro encontrado...</S.TextLoading>

      <S.AddButton color='primary' onClick={showCreateProduct}>
        <AddIcon />
      </S.AddButton>
    </S.Wrapper>
  );
}
