import axios from 'axios';

const getTokenAccess = () => localStorage.getItem('user_token');

const ApiInterceptors = axios.create({ baseURL: process.env.REACT_APP_HOST });

ApiInterceptors.interceptors.request.use(
  async (config) => {
    const customConfig = config;

    customConfig.headers['ngrok-skip-browser-warning'] = true;

    if (!config.headers['Content-Type']) {
      customConfig.headers['Content-Type'] = 'application/json';
    }

    if (getTokenAccess() !== null) {
      customConfig.headers.Authorization = `Bearer ${getTokenAccess()}`;
    }

    return customConfig;
  },
  (error) => Promise.reject(error),
);

ApiInterceptors.interceptors.response.use(
  ({ data }) => data,
  ({ response, message }) => {
    if (message === 'Network Error') {
      return Promise.reject({ status: 500, message: 'Servidor indisponível' });
    }

    if (response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }

    return Promise.reject({
      status: response.status,
      message: response.data.message,
    });
  },
);

export default ApiInterceptors;
