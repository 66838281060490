import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function Alerts({ open, handleClose, status, message }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={2500}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={status || 'success'} sx={{ width: '100%' }}>
        {message || 'Sucesso!'}
      </Alert>
    </Snackbar>
  );
}
