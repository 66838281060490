import React from 'react';

import * as S from './Styles';

export default function DataEmpty() {
  return (
    <S.Wrapper>
      <S.TextLoading>Realize uma consulta...</S.TextLoading>
    </S.Wrapper>
  );
}
