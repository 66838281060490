import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Toolbar,
  Typography,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Loading from '../components/Loading';
import MaterialIcon from '../components/MaterialIcon';
import MyApi from '../connections/Api';
import { generateKey, getTokenAccess } from '../functions';
import Accounting from '../pages/Providers/Accounting';
import Challenge from '../pages/Providers/Challenge';
import ChangePassword from '../pages/Providers/ChangePassword';
import Company from '../pages/Providers/Company';
import Dashboard from '../pages/Providers/Dashboard';
import Filter from '../pages/Providers/Dashboard/Components/Modals/Filter';
import Employee from '../pages/Providers/Employee';
import InternalOrders from '../pages/Providers/InternalOrders';
import ManageHistory from '../pages/Providers/ManageHistory';
import Menu from '../pages/Providers/Menu';
import ReleaseNotes from '../pages/Providers/ReleaseNotes';
import Schedule from '../pages/Providers/Schedule';

import * as S from './Styles';

const Components = {
  Menu,
  Company,
  Employee,
  Schedule,
  Dashboard,
  ChangePassword,
  Accounting,
  ManageHistory,
  Challenge,
  InternalOrders,
  ReleaseNotes,
};

const toolbarTranslation = {
  menu: 'Cardápio',
  company: 'Empresas',
  employee: 'Funcionários',
  schedule: 'Agendamentos',
  dashboard: 'Comandas',
  safety: 'Alterar Senha',
  accounting: 'Contabilidade',
  manageHistory: 'Gerir Histórico',
  challenge: 'Desafio do Bagre',
  internalOrders: 'Comandas Internas',
  releaseNotes: 'Atualizações do Sistema',
};

const openedMixin = (theme) => ({
  width: 240,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: 240,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MySwal = withReactContent(Swal);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function Template({ history, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [searchEmployee, setSearchEmployee] = React.useState('');
  const [searchDashboard, setSearchDashboard] = React.useState('');
  const [filterDashboard, setFilterDashboard] = React.useState('');
  const [routes, setRoutes] = React.useState(null);
  const [showFilterProduct, setShowFilterProduct] = React.useState(false);

  const showMessage = async () =>
    MySwal.fire({
      title: 'Deseja sair da seção?',
      text: 'Você poderá voltar assim que fizer login novamente.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, quero deslogar!',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        history.push('/login');
      }
    });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const showReleaseNotes = async () => {
    history.push(`/sistema/releaseNotes`);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function PrivateRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          getTokenAccess() ? (
            <Component {...props} component={Component} />
          ) : (
            <Redirect to={{ pathname: '/404', state: { from: props?.location || '' } }} />
          )
        }
      />
    );
  }

  const getRoutes = (routesList) => {
    const routes = [
      ...routesList,
      {
        path: '/releaseNotes',
        name: 'Notas de atualização',
        icon: 'new_releases',
        component: 'ReleaseNotes',
        layout: '/sistema',
        section: 2,
      },
    ];

    const [, pathname, localPath] = rest.location.pathname.split('/');
    const baseRoutes = routes.map(({ path }) => path.replace('/', ''));

    if (pathname === 'sistema' && baseRoutes.includes(localPath)) {
      return routes.map(({ layout, path, component }) => (
        <PrivateRoute
          exact
          key={generateKey()}
          path={layout + path}
          component={Components[component]}
        />
      ));
    }
    return <Redirect to={{ pathname: '/404', state: { from: rest.location } }} />;
  };

  const isEqualPath = (pathName) => {
    const pathList = rest.location.pathname.split('/');

    return pathList[pathList.length - 1] === String(pathName);
  };

  const renderTextRoutes = (section = 1) =>
    routes
      .filter((item) => item.section === section)
      .map(({ name, icon, layout, path }) => (
        <ListItemButton
          key={generateKey()}
          onClick={() => {
            setOpen(false);
            history.push(`${layout}${path}`);
          }}
        >
          <ListItemIcon>
            <MaterialIcon name={icon} size={24} />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      ));

  const getSimpleName = (name) => {
    const arrayNames = name.split(' ');

    return arrayNames.map((name, key) =>
      arrayNames.length === key + 1 || key === 0 ? ` ${name}` : ` ${name[0]}`,
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const id = localStorage.getItem('user_level_id');
      const { allowed_pages } = await MyApi.get(`/access-level/find?id=${id}`);

      setSearch(localStorage.getItem('search'));
      setSearchEmployee(localStorage.getItem('search_employee'));
      setSearchDashboard(localStorage.getItem('search_dashboard'));
      setFilterDashboard(
        localStorage.getItem('filter') === 'null'
          ? ''
          : JSON.parse(localStorage.getItem('filter'))?.label ?? '',
      );

      setRoutes(allowed_pages);
    };

    return fetchData();
  }, []);

  if (routes === null) return <Loading />;

  const searchItemMenu = (query) => {
    setSearch(query);
    localStorage.setItem('search', query);
  };

  const searchItemEmployee = (query) => {
    setSearchEmployee(query);
    localStorage.setItem('search_employee', query);
  };

  const searchItemDashboard = (q) => {
    setSearchDashboard(q);
    localStorage.setItem('search_dashboard', q);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '10px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" style={{ marginRight: '10px' }}>
              {toolbarTranslation[rest.location.pathname.split('/')[2]]}
            </Typography>
          </div>

          <Divider style={{ width: '5px' }} />
          {isEqualPath('menu') && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={search}
                onChange={(e) => searchItemMenu(e.target.value)}
                placeholder="Buscar…"
              />
            </Search>
          )}
          {isEqualPath('employee') && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchEmployee}
                onChange={(e) => searchItemEmployee(e.target.value)}
                placeholder="Buscar…"
              />
            </Search>
          )}
          {isEqualPath('dashboard') && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  value={searchDashboard}
                  onChange={(e) => searchItemDashboard(e.target.value)}
                  placeholder="Buscar…"
                />
              </Search>
              <IconButton
                size="large"
                aria-label="display more actions"
                edge="end"
                color="inherit"
                onClick={() => setShowFilterProduct(true)}
              >
                <FilterIcon />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>

      {open && (
        <Drawer style={{ zIndex: 0 }} variant="permanent" open={open}>
          <DrawerHeader>
            <div style={{ width: '100%' }}>
              <p style={{ fontSize: 11 }}>{localStorage.getItem('user_level_name')}</p>
              <p>{getSimpleName(localStorage.getItem('user_name'))}</p>
            </div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>

          <Divider />
          <List>{renderTextRoutes(1)}</List>
          <Divider />
          <List>{renderTextRoutes(2)}</List>

          <S.ButtonReport>
            <List>
              <ListItemButton onClick={showMessage}>
                <ListItemIcon>
                  <MaterialIcon name="logout" color="gray" />
                </ListItemIcon>
                <ListItemText style={{ color: 'gray' }} primary="Sair" />
              </ListItemButton>
            </List>

            <List>
              <ListItemButton onClick={showReleaseNotes}>
                <ListItemIcon>
                  <MaterialIcon name="new_releases" color="gray" />
                </ListItemIcon>
                <ListItemText style={{ color: 'gray' }} primary="Atualizações" />
              </ListItemButton>
            </List>
          </S.ButtonReport>
        </Drawer>
      )}

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {search && isEqualPath('menu') && (
          <Typography
            variant="h8"
            component="div"
            style={{ marginBottom: '10px', marginTop: '-5px' }}
          >
            {`Resultados para: "${search}"`}
          </Typography>
        )}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {searchDashboard && isEqualPath('dashboard') && (
            <Typography
              variant="h8"
              component="div"
              style={{
                padding: '3px',
                marginTop: '-5px',
                borderRadius: '4px',
                marginBottom: '10px',
                border: '2px solid gray',
                backgroundColor: 'lightgray',
              }}
            >
              {`Resultados para: "${searchDashboard}"`}
            </Typography>
          )}
          <div style={{ width: '6px' }} />
          {filterDashboard && isEqualPath('dashboard') && (
            <Typography
              variant="h8"
              component="div"
              style={{
                padding: '3px',
                marginTop: '-5px',
                borderRadius: '4px',
                marginBottom: '10px',
                border: '2px solid gray',
                backgroundColor: 'lightgray',
              }}
            >
              {`Grupo: "${filterDashboard}"`}
            </Typography>
          )}
        </div>
        <Switch>{getRoutes(routes, 1)}</Switch>
      </Box>
      <Filter open={showFilterProduct} handleClosed={() => setShowFilterProduct(false)} />
    </Box>
  );
}

export default Template;
