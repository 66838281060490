import React, { useState } from 'react';

import Forgot from './Forgot';
import Login from './Login';

export default function Authentications({ history }) {
  const [pageData, setPageData] = useState({ page: 'Login', data: '' });

  const handlePage = (page, data = '') => {
    setPageData({ page, data });
  };

  return (
    <div>
      {
        {
          Login: <Login data={pageData.data} setPage={handlePage} history={history} />,
          Forgot: <Forgot data={pageData.data} setPage={handlePage} />,
        }[pageData.page]
      }
    </div>
  );
}
