import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import Api from '../../../../../connections/Api';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { orderByGroupName } from '../../../../../functions';

export default function Filter({ open, handleClosed }) {
  const [value] = React.useState(
    localStorage.getItem('filter') === null
      ? ''
      : JSON.parse(localStorage.getItem('filter')),
  );
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await Api.get('/order/list');
      const organizedResult = result.data
        .filter((item) => 'order_group' in item)
        .filter(({ order_group }) => order_group !== null)
        .sort(orderByGroupName);

      setOptions(
        [...new Set(organizedResult.map(({ order_group }) => order_group))].map(
          (label) => ({ label }),
        ),
      );
    };

    fetchData();
  }, []);

  function cleanFilter() {
    window.location.reload();
    localStorage.setItem('filter', null);
  }

  return (
    <Dialog open={open} onClose={handleClosed} fullScreen>
      <DialogTitle>FILTROS</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 3 }}>
          Aplique filtro nas comandas.
        </DialogContentText>
        <form id="formEdit" onSubmit={handleClosed}>
          <Autocomplete
            id="filter"
            onChange={(e, inputValue) => {
              localStorage.setItem('filter', JSON.stringify(inputValue));
            }}
            margin="dense"
            disableClearable
            fullWidth
            options={options}
            value={value?.label}
            style={{ marginTop: '20px' }}
            renderInput={(params) => <TextField {...params} label="Grupos" />}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => cleanFilter()}>
          Limpar Filtro
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={() => window.location.reload()}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
